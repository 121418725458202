import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import {
  ENVIRONMENT,
  FilledButton,
  Loader,
  showError,
  useQueryParams
} from 'shared';

import { tavusPersonaStore } from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { conversationStore } from 'entities/tavus/conversation';
import { TavusEmbedConference } from './TavusEmbedConference';
import { Replica } from 'entities/tavus/replica';

export const TavusCallEmbed = () => {
  const { startConversation, currentConversation, conversationLoading } =
    conversationStore();
  const { fetchOne, currentPersona, setCurrentPersona } = tavusPersonaStore();
  const { id } = useParams();
  const query = useQueryParams();
  const getReplicaById = replicaStore((state) => state.getReplicaById);
  const [replica, setReplica] = useState<Replica | undefined>();

  useEffect(() => {
    getPersonaData(id);
  }, []);

  const getPersonaData = async (id: string | undefined) => {
    if (!id) return showError("Can't get agent. Please check embed url");
    const persona = await fetchOne(id, false).then((success) => {
      !success && showError('You need to refresh your link');
      if (success) {
        return success;
      }
    });
    if (persona) {
      setCurrentPersona(persona);
      setReplica(getReplicaById(persona.default_replica_id));
    }
  };

  const startCall = () => {
    let key = query.get('key') || undefined;
    if (!key) {
      if (ENVIRONMENT === 'development' || ENVIRONMENT === 'local') {
        key = '3dd8b41b-4e2f-4465-a7fe-b5029f5851e3';
      }
    }
    startConversation(currentPersona, key);
  };

  return (
    <div className={`ag-w-full ag-bg-white ag-text-neutral-900`}>
      {!currentPersona ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center">
          <Loader size={50} />
        </div>
      ) : (
        <div className="ag-h-screen ag-flex ag-flex-col ag-overflow-hidden">
          <div className="ag-relative ag-z-50 ag-left-0 ag-top-0 ag-w-full ag-border-b ag-bg-white">
            <div className="ag-flex ag-flex-col ag-justify-center ag-items-center ag-p-3 ag-text-xs">
              <div className="ag-font-semibold">
                {currentPersona.persona_name}
              </div>
              <div className="ag-text-neutral-600">Powered by yepic ai</div>
            </div>
          </div>

          <>
            {conversationLoading ? (
              <div className="ag-w-full ag-bg-white ag-flex ag-items-center ag-justify-center ag-h-screen">
                <Loader size={50} />
              </div>
            ) : !currentConversation ? (
              <div className="ag-w-full ag-grow ag-flex ag-justify-center ag-items-center">
                <div className="ag-w-56 lg:ag-w-96 ag-flex ag-flex-col ag-items-center ag-justify-center ag-mx-auto ">
                  <div className="ag-w-56 ag-h-56 ag-rounded-full ag-overflow-hidden ag-mb-7">
                    {replica ? (
                      replica.thumbnail_url ? (
                        <img
                          className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                          src={replica?.thumbnail_url}
                        />
                      ) : (
                        <video
                          className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
                          src={replica.thumbnail_video_url}
                        />
                      )
                    ) : (
                      <div className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top ag-bg-neutral-100" />
                    )}
                  </div>
                  <FilledButton
                    className="ag-w-full ag-py-2 ag-mb-7"
                    onClick={startCall}
                  >
                    Start new chat
                  </FilledButton>
                </div>
              </div>
            ) : (
              <div className={`ag-bg-white ag-grow ag-shrink-0`}>
                <TavusEmbedConference />
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};
