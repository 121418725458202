import * as React from 'react';
import { FC, useEffect, useRef } from 'react';

import {
  LayoutContextProvider,
  RoomAudioRenderer,
  TrackRefContext,
  useCreateLayoutContext
} from '@livekit/components-react';

import { LogLevel, RoomEvent } from 'livekit-client';

import { ENVIRONMENT, useDebugMode } from 'shared';
import {
  useConnectionStateToast,
  useBotTrack,
  videoCallStore,
  ParticipantTile
} from 'features/livekit-call';

import { ControlBar } from 'widgets/call-controls';

import { StatusConnection } from 'widgets/connection-status';
import { ToggleCaptions } from 'features/toggle-captions';

export const VideoConference = () => {
  const {
    isConnected,
    isVideoStarted,
    toggleIsConnected,
    withCaptions,
    toggleCaptions
  } = videoCallStore();

  const connectionFallbackRef = useRef<NodeJS.Timeout>();

  useConnectionStateToast();

  const track = useBotTrack({
    updateOnlyOn: [RoomEvent.ActiveSpeakersChanged]
  });

  useEffect(() => {
    if ((isConnected && !track) || (!isConnected && track)) toggleIsConnected();
  }, [track, isConnected]);

  const layoutContext = useCreateLayoutContext();

  useDebugMode({
    logLevel:
      ENVIRONMENT === 'development' || ENVIRONMENT === 'local'
        ? LogLevel.debug
        : LogLevel.error
  });

  useEffect(() => {
    if (isVideoStarted && connectionFallbackRef.current) {
      clearTimeout(connectionFallbackRef.current);
      connectionFallbackRef.current = undefined;
    }
  }, [isConnected, isVideoStarted]);

  return (
    <div
      className={`ag-bg-neutral-900 ag-h-screen ag-flex ag-items-center ag-justify-center ag-relative`}
    >
      <LayoutContextProvider value={layoutContext}>
        <TrackRefContext.Provider value={track}>
          <ParticipantTile trackRef={track} />
        </TrackRefContext.Provider>
        <ControlBar />
      </LayoutContextProvider>
      <div className="ag-flex ag-items-center ag-gap-6 ag-absolute ag-top-3.5 ag-right-3.5">
        <ToggleCaptions
          withCaptions={withCaptions}
          toggleCaptions={toggleCaptions}
        />
        <StatusConnection />
      </div>

      {/* <ErrorMessage /> */}
      <RoomAudioRenderer />
    </div>
  );
};
