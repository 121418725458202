import { ENVIRONMENT } from 'shared/model';

const isShown = ENVIRONMENT !== 'production' && ENVIRONMENT !== 'beta';

export const logger = {
  debug: (...args: any[]) => {
    if (isShown) {
      console.log(...args);
    }
  },
  trace: (...args: any[]) => {
    if (isShown) {
      console.trace(...args);
    }
  },
  error: (...args: any[]) => {
    if (isShown) {
      console.error(...args);
    }
  }
};
