import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import Modal from 'components/shared/Modal';
import Divider from 'components/Divider';
import { IAvatar } from 'shared/model';
import { useAuthStore } from 'stores/useAuthStore';
import { avatarStore } from 'entities/avatar';
import { TalkingPhotoListItem } from './TalkingPhotoListItem';
import { IoSearchOutline } from 'react-icons/io5';
import { useDebounce } from 'shared/lib';
import { FilledButton, OutlinedButton } from 'shared/ui';
import { FiltersDropdown } from './FiltersDropdown';
import { SelectedFilters } from './SelectedFilters';

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  onChangeAvatarId: (value: string) => void;
}

export const TalkingPhotoListModal: FC<IProps> = ({
  setOpen,
  open,
  onChangeAvatarId
}) => {
  const { isAuth, toggleCreateAccModal } = useAuthStore();
  const [nameInputValue, setNameInputValue] = useState('');
  const [nameFilterValue, setNameFilterValue] = useState('');
  const changeFilterNameValue = useDebounce(setNameFilterValue, 500);

  useEffect(() => {
    changeFilterNameValue(nameInputValue);
  }, [nameInputValue]);

  const {
    activeAvatar,
    selectAvatar,
    filteredTalkingPhotos,
    filteredCustomTalkingPhotos,
    toggleUploadAvatar,
    typeFilters
  } = avatarStore((state) => ({
    activeAvatar: state.activeAvatar,
    selectAvatar: state.selectAvatar,
    filteredTalkingPhotos: state.filteredTalkingPhotos,
    filteredCustomTalkingPhotos: state.filteredCustomTalkingPhotos,
    genderFilters: state.genderFilters,
    setGenderFilters: state.setGenderFilters,
    resetGenders: state.resetGenders,
    toggleUploadAvatar: state.toggleUploadAvatar,
    typeFilters: state.typeFilters
  }));

  const [selectedAvatar, setSelectedAvatar] = useState(activeAvatar);

  useEffect(() => {
    if (!open) {
      setSelectedAvatar(null);
    } else {
      setSelectedAvatar(activeAvatar);
    }
  }, [open]);

  const filterByName = (avatar: IAvatar) => {
    return avatar.name.toLowerCase().includes(nameFilterValue.toLowerCase());
  };

  const filteredCustomTalkingPhotosByName =
    filteredCustomTalkingPhotos?.filter(filterByName) || [];
  const hasFilteredCustomTalkingPhotos =
    !!filteredCustomTalkingPhotosByName.length;

  const showCustomTalkingPhotos =
    (!typeFilters.length || typeFilters.includes('custom')) &&
    hasFilteredCustomTalkingPhotos;

  const filteredTalkingPhotosByName =
    filteredTalkingPhotos?.filter(filterByName) || [];
  const hasFilteredTalkingPhotos = !!filteredTalkingPhotosByName.length;
  const showTalkingPhotos =
    (!typeFilters.length || typeFilters.includes('talking photo')) &&
    hasFilteredTalkingPhotos;

  return (
    <Modal
      className={
        'ag-max-w-[40.5rem] ag-max-h-[calc(100vh-5rem)] ag-overflow-y-auto'
      }
      noPadding
      setOpen={(value) => {
        setOpen(value);
      }}
      open={open}
    >
      <div>
        <h2 className={'ag-p-6 ag-font-semibold ag-text-lg'}>Avatars</h2>
        <Divider />
      </div>
      <div className={'ag-flex ag-flex-col'}>
        <div className="ag-p-4 z-20 ag-flex">
          <div className={'ag-relative'}>
            <IoSearchOutline
              size={20}
              className={
                'ag-absolute ag-text-neutral-500 ag-left-3 ag-top-1/2 ag-transform -ag-translate-y-1/2'
              }
            />
            <input
              className="ag-rounded-md ag-py-2 ag-pl-10 ag-pr-3 ag-border-neutral-300 ag-max-w-80 ag-h-10 ag-outline-none ag-ring-0 focus:ag-ring-0 focus:ag-outline-none focus:ag-border-primary-800 ag-mr-2"
              type="text"
              value={nameInputValue}
              onChange={(e) => setNameInputValue(e.target.value)}
              placeholder={'Avatar name'}
            />
          </div>
          <FiltersDropdown />
        </div>
        <SelectedFilters />
        <div className="ag-px-4 ag-h-[calc(60vh-5rem)] ag-overflow-y-auto ag-scrollbar-none ag-min-h-48">
          {!showCustomTalkingPhotos && !showTalkingPhotos && (
            <div className={'ag-text-neutral-600 ag-text-sm'}>
              No results found.
            </div>
          )}
          <>
            {showCustomTalkingPhotos && (
              <div className={'ag-mb-6'}>
                <h3 className="ag-text-sm ag-font-semibold ag-mb-2">
                  My Avatars
                </h3>
                <div className="ag-flex ag-gap-2 ag-flex-wrap">
                  <div
                    onClick={() => {
                      isAuth ? toggleUploadAvatar() : toggleCreateAccModal();
                      setOpen(false);
                    }}
                    className={
                      'ag-cursor-pointer ag-w-24 ag-h-24 ag-bg-primary-200 ag-rounded-md ag-flex ag-justify-center ag-items-center ag-text-primary-600 ag-text-2xl'
                    }
                  >
                    +
                  </div>
                  {filteredCustomTalkingPhotosByName.map((avatar, idx) => (
                    <TalkingPhotoListItem
                      key={avatar.id}
                      onChooseAvatar={(avatar) => setSelectedAvatar(avatar)}
                      isActive={selectedAvatar?.id === avatar.id}
                      avatar={avatar}
                    />
                  ))}
                </div>
              </div>
            )}
            {showTalkingPhotos && (
              <div>
                <h3 className="ag-text-sm ag-font-semibold ag-mb-2">
                  Stock Avatars
                </h3>
                <div className="ag-flex ag-gap-2 ag-flex-wrap">
                  {filteredTalkingPhotosByName?.map((avatar, inx) => (
                    <TalkingPhotoListItem
                      key={inx}
                      avatar={avatar}
                      onChooseAvatar={(avatar) => setSelectedAvatar(avatar)}
                      isActive={selectedAvatar?.id === avatar.id}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      <Divider />
      <div className={'ag-p-6 ag-flex ag-justify-end ag-gap-2'}>
        <OutlinedButton
          onClick={() => {
            setOpen(false);
          }}
          className={'ag-py-2.5 ag-px-[18px]'}
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          onClick={() => {
            if (selectedAvatar) {
              onChangeAvatarId(selectedAvatar.id);
              selectAvatar(selectedAvatar);
            }
            setOpen(false);
          }}
          className={'ag-py-2.5 ag-px-[18px]'}
        >
          Apply
        </FilledButton>
      </div>
    </Modal>
  );
};
