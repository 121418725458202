import { create } from 'zustand';
import { DAILY_CALL_STATES } from './dailyCallModel';
import DailyIframe, {
  DailyCall,
  DailyEventObjectTranscriptionMessage
} from '@daily-co/daily-js';
import { logger } from 'shared/lib';
import { ITMessageDaily, ChatRole } from 'shared/model';
import { tavusPersonaStore } from 'entities/tavus/persona';

type State = {
  currentState: string;
  callObject: DailyCall | null;
  messages: ITMessageDaily[];
  isRecordingMic: boolean;
  withCaptions: boolean;
};

type Action = {
  setCurrentState: (currentState: string) => void;
  setCallObject: (callObject: DailyCall | null) => void;
  startLeavingCall: () => void;
  startHairCheck: (url: string) => void;
  joinCall: (url: string) => void;
  handleNewMeetingState: () => void;
  addMessage: (message: DailyEventObjectTranscriptionMessage) => void;
  startMic: () => void;
  stopMic: () => void;
  toggleCaptions: () => void;
};

type Store = State & Action;

export const dailyCallStore = create<Store>((set, get) => ({
  currentState: DAILY_CALL_STATES.STATE_IDLE,
  setCurrentState: (currentState: string) => set({ currentState }),
  callObject: null,
  isRecordingMic: true,
  startMic: () => {
    set({ isRecordingMic: true });
  },
  stopMic: () => {
    set({ isRecordingMic: false });
  },
  setCallObject: (callObject: DailyCall | null) => set({ callObject }),
  startLeavingCall: () => {
    const callObject = get().callObject;
    if (!callObject) return;
    if (get().currentState === DAILY_CALL_STATES.STATE_ERROR) {
      callObject?.destroy().then(() => {
        set({
          callObject: null,
          currentState: DAILY_CALL_STATES.STATE_IDLE
        });
        DailyIframe.getCallInstance()?.destroy();
      });
    } else {
      set({ currentState: DAILY_CALL_STATES.STATE_LEAVING });
      callObject?.leave();
      DailyIframe.getCallInstance()?.destroy();
      set({ callObject: null, currentState: DAILY_CALL_STATES.STATE_IDLE });
    }
  },
  startHairCheck: async (url: string) => {
    const newCallObject = DailyIframe.createCallObject();
    set({
      callObject: newCallObject,
      currentState: DAILY_CALL_STATES.STATE_HAIRCHECK,
      messages: []
    });
    await newCallObject.preAuth({ url });
  },
  joinCall: async (url) => {
    const callObject = get().callObject;
    await callObject?.join({ url, userName: 'user' });
  },
  handleNewMeetingState: () => {
    switch (get().callObject?.meetingState()) {
      case 'joined-meeting':
        set({ currentState: DAILY_CALL_STATES.STATE_JOINED });
        break;
      case 'left-meeting':
        get()
          .callObject?.destroy()
          .then(() => {
            set({
              callObject: null,
              currentState: DAILY_CALL_STATES.STATE_IDLE
            });
          });
        break;
      case 'error':
        set({ currentState: DAILY_CALL_STATES.STATE_ERROR });
        break;
      default:
        break;
    }
  },
  withCaptions: false,
  toggleCaptions: () => {
    set((state) => ({ withCaptions: !state.withCaptions }));
  },
  messages: [],
  addMessage: (messageObject) => {
    const messages = get().messages;
    const currentPersona = tavusPersonaStore.getState().currentPersona;
    if (messages.length === 0) {
      const message = {
        content: messageObject.text,
        timestamp: messageObject.timestamp,
        role: ChatRole.ASSISTANT,
        participantId: messageObject.participantId,
        userName: currentPersona?.persona_name
      };
      set({ messages: [message] });
      return;
    }
    const isContinuing =
      messages.length > 0 &&
      messages[messages.length - 1].participantId ===
        messageObject.participantId;

    if (isContinuing) {
      const updatedMessage = (messages[messages.length - 1].content +=
        ' ' + messageObject.text);
      messages[messages.length - 1].content = updatedMessage;
    } else {
      const lastRole = messages[messages.length - 1].role;

      const currentRole = isContinuing
        ? lastRole
        : lastRole === ChatRole.ASSISTANT
        ? ChatRole.USER
        : ChatRole.ASSISTANT;

      const message = {
        content: messageObject.text,
        timestamp: messageObject.timestamp,
        role: currentRole,
        participantId: messageObject.participantId,
        userName:
          currentRole === ChatRole.ASSISTANT
            ? currentPersona?.persona_name
            : 'User'
      };
      messages.push(message);
    }
    logger.debug(messages[messages.length - 1]);

    set({ messages: [...messages] });
  }
}));
