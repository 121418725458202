import clsx from 'clsx';
import DropZone from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/components/DropZone';
import { assetStore } from 'entities/asset';
import { IKnowledgeReq, IPersonaFormik, KnowledgeType } from 'entities/persona';
import { FormikErrors, FormikProps } from 'formik';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaRegFile } from 'react-icons/fa';
import { cn } from 'shared/lib';
import { showError } from 'shared/model';
import { Loader } from 'shared/ui';
import { KnowledgeTooltip } from './KnowledgeTooltip';

type Props = {
  files: string[];
  formik: FormikProps<IPersonaFormik>;
  onChange: (
    value: IKnowledgeReq[]
  ) => Promise<void> | Promise<FormikErrors<IPersonaFormik>>;
  onChangeFile: (files: string[]) => void;
};

export const Knowledge: FC<Props> = ({
  onChange,
  formik,
  files,
  onChangeFile
}) => {
  const { uploadFile, isUploading } = assetStore();

  const [currentTab, setCurrentTab] = useState<KnowledgeType>(
    formik.values.knowledgeSources && formik.values.knowledgeSources.length > 0
      ? formik.values.knowledgeSources[0].type
      : 'Ungrounded'
  );

  const handleChangeTab = (tab: KnowledgeType) => {
    setCurrentTab(tab);
    handleChangeType(tab);
  };
  const handleChange = useCallback(
    async (e: DragEvent | ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const uploadingFiles: FileList | null | undefined =
        'dataTransfer' in e ? e.dataTransfer?.files : e.target.files;

      if (!uploadingFiles) return;

      const file = uploadingFiles.item(0);
      if (e.target && 'files' in e.target) e.target.files = null;

      if (file) {
        if (files.includes(file.name)) {
          showError(
            'File with this name already uploaded. Please rename it or delete the existing one'
          );
          return;
        }
        if (
          !file.name.toLowerCase().endsWith('.pdf') &&
          file.type !== 'application/pdf' &&
          !file.name.toLowerCase().endsWith('.txt') &&
          file.type !== 'text/plain' &&
          !file.name.toLowerCase().endsWith('.docx') &&
          file.type !==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
          !file.name.toLowerCase().endsWith('.doc') &&
          file.type !== 'application/msword'
        ) {
          showError('Please upload a PDF, text, doc or docx file');
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('Type', 'Text');
        formData.append('Name', file.name);

        const res = await uploadFile(formData);

        if (res !== undefined) {
          const newKnowledge = [
            ...formik.values.knowledgeSources,
            {
              assetId: res.id,
              type: currentTab
            }
          ];

          onChangeFile([...files, file.name]);
          onChange(newKnowledge);
        }
      }
    },
    [formik.values.knowledgeSources, currentTab]
  );

  const handleChangeType = (type: KnowledgeType) => {
    const newKnowl = formik.values.knowledgeSources.map((kn) => ({
      ...kn,
      type
    }));
    onChange(newKnowl);
  };

  const handleDeleteFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChangeFile(newFiles);

    const newKnowl = [...formik.values.knowledgeSources];
    newKnowl.splice(index, 1);
    onChange(newKnowl);
  };

  return (
    <div>
      <div className="ag-text-neutral-900 ag-font-semibold">Knowledge</div>
      <div className="ag-text-neutral-600 ag-text-sm">
        Enter information the Video Agent needs to know.
      </div>
      <KnowledgeTooltip />
      <div className="ag-relative">
        <div className="ag-flex ag-items-center ag-gap-4 ag-text-sm ag-text-neutral-500 ag-font-semibold ag-mb-4 ag-group ag-text-center ag-bg-neutral-100 ag-p-1 ag-rounded-lg">
          <div
            onClick={() => handleChangeTab('Ungrounded')}
            className={clsx(
              'ag-rounded-md ag-px-3 ag-py-2 ag-transition-all ag-duration-300 ag-flex-1',
              currentTab === 'Ungrounded'
                ? 'ag-text-neutral-700 ag-bg-white ag-shadow-top'
                : 'ag-cursor-pointer hover:ag-text-neutral-500 hover:ag-bg-white'
            )}
          >
            Ungrounded
          </div>
          <div
            onClick={() => handleChangeTab('Hybrid')}
            className={clsx(
              'ag-rounded-md ag-px-3 ag-py-2 ag-transition-all ag-duration-300 ag-flex-1',
              currentTab === 'Hybrid'
                ? 'ag-text-neutral-700 ag-bg-white ag-shadow-top'
                : 'ag-cursor-pointer hover:ag-text-neutral-500 hover:ag-bg-white'
            )}
          >
            Hybrid
          </div>
        </div>
        <DropZone onDrop={handleChange}>
          {() => (
            <label
              htmlFor="input-file-upload"
              className={cn(
                `ag-flex ag-items-center ag-justify-center ag-text-center ag-rounded-xl ag-border ag-min-h-[130px] ag-text-neutral-400 ag-border-neutral-200 ag-mb-6`,
                currentTab === 'Ungrounded'
                  ? 'ag-opacity-50'
                  : 'ag-cursor-pointer'
              )}
            >
              <input
                type="file"
                id="input-file-upload"
                name="input-file-upload"
                multiple={false}
                accept="application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                className="ag-hidden"
                disabled={currentTab === 'Ungrounded' || isUploading}
                onChange={handleChange}
              />
              <div
                className={clsx(
                  'ag-p-4',
                  (currentTab !== 'Ungrounded' || isUploading) &&
                    'ag-cursor-pointer'
                )}
              >
                <div
                  className={clsx(
                    'ag-w-11 ag-h-11 ag-rounded-full ag-mx-auto ag-flex ag-justify-center ag-items-center ag-mb-3 ag-bg-neutral-100',
                    currentTab !== 'Ungrounded' && 'ag-cursor-pointer'
                  )}
                >
                  <div
                    className={clsx(
                      'ag-w-8 ag-h-8 ag-rounded-full ag-flex ag-items-center ag-justify-center ag-mx-auto ag-bg-neutral-100'
                    )}
                  >
                    {isUploading ? (
                      <Loader />
                    ) : (
                      <FiUploadCloud
                        className={clsx(
                          'ag-mx-auto ag-text-lg ag-text-neutral-900'
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className={clsx('ag-text-sm ag-mb-1')}>
                  <span
                    className={clsx(
                      ' ag-font-semibold ag-mr-1 ag-text-primary-700'
                    )}
                  >
                    Click to upload
                  </span>
                  <span className="ag-text-neutral-600 ag-text-sm">
                    or drag and drop
                  </span>
                  <div className="ag-text-neutral-600 ag-text-xs">
                    PDF, text, doc or docx formats only
                  </div>
                </div>
              </div>
            </label>
          )}
        </DropZone>

        <div className="ag-space-y-2 ag-mb-6">
          {formik.values.knowledgeSources &&
            formik.values.knowledgeSources.length > 0 && (
              <>
                {formik.values.knowledgeSources.map((kn, ind) => (
                  <div
                    key={kn.assetId + ind}
                    className={clsx(
                      'ag-rounded-xl ag-border ag-p-3 ag-flex ag-items-center ag-justify-between ag-border-neutral-300 ag-text-neutral-600'
                    )}
                  >
                    <div className="ag-flex ag-items-center ag-w-3/4">
                      <div
                        className={clsx(
                          'ag-w-8 ag-h-8 ag-flex ag-items-center ag-justify-center ag-rounded-full ag-shrink-0 ag-mr-2 ag-bg-neutral-400'
                        )}
                      >
                        <FaRegFile />
                      </div>
                      <div className="ag-truncate ag-mr-2">{files[ind]}</div>
                    </div>
                    <RiDeleteBinLine
                      onClick={() => handleDeleteFile(ind)}
                      className={clsx('ag-shrink-0 ag-cursor-pointer')}
                    />
                  </div>
                ))}
              </>
            )}
        </div>
      </div>
    </div>
  );
};
