import { userStore } from 'entities/user';
import { useEffect, useState } from 'react';
import { getErrorMessageV2 } from 'shared/lib';
import { IUser, showError } from 'shared/model';
import { useAuthStore } from 'stores/useAuthStore';

const generateCode = (personaId: string, internalKey: string | undefined) => {
  const origin = window.location.origin;

  const url = `<iframe width='100%' height='100%' src='${origin}/video-agents/avatars/embed/${personaId}?key=${internalKey}' allow='microphone; camera'/>`;

  return url;
};

export const generateWidgetCode = (
  personaId: string,
  internalKey: string | undefined
) => {
  const origin = window.location.origin;

  const urls = [
    `<link rel='stylesheet' href='${origin}/widget/style.css'>
<script src='${origin}/widget/script.js'></script>`,
    `<script>
document.addEventListener('DOMContentLoaded', (event) => {
  initYepicChatWidget({ url: '${origin}/video-agents/avatars/embed/${personaId}?key=${internalKey}' });
});
</script>`,
    `${origin}/video-agents/avatars/event/${personaId}`,
    `${origin}/video-agents/avatars/event2`,
    `${origin}/video-agents/avatars/embed/${personaId}?key=${internalKey}`
  ];

  return urls;
};

export const whereOptions = [
  'nowhere',
  'everywhere',
  'specific domain'
] as const;

export type WhereOptions = typeof whereOptions[number];

export const useTavusGenerateEmbedCode = (personaId: string) => {
  const user = userStore((state) => state.user);
  const [embedCode, setEmbedCode] = useState(
    generateCode(personaId, user?.internalKey)
  );
  const [widgetCode, setWidgetCode] = useState(
    generateWidgetCode(personaId, user?.internalKey)
  );
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [where, setWhere] = useState<WhereOptions>('everywhere');

  useEffect(() => {
    setAllowedDomains([]);
    setEmbedCode(generateCode(personaId, user?.internalKey));
    setWidgetCode(generateWidgetCode(personaId, user?.internalKey));
  }, [personaId, user]);

  const onAddDomain = (domain: string) => {
    setAllowedDomains((prev) => [...prev, domain]);
  };

  const handleChangeWhere = (value: WhereOptions) => {
    setWhere(value);
  };

  const removeDomainName = (index: number) => {
    const allowed = [...allowedDomains];
    allowed.splice(index, 1);
    setAllowedDomains(allowed);
  };

  const generateEmbedCode = () => {
    setEmbedCode(generateCode(personaId, user?.internalKey));
  };

  return {
    embedCode,
    allowedDomains,
    onAddDomain,
    generateEmbedCode,
    where,
    handleChangeWhere,
    removeDomainName,
    widgetCode
  };
};
