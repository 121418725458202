export const PREFIX = '/video-agents';

export const MAIN_ROUTE = PREFIX;
export const CREATE_PERSONA_ROUTE = PREFIX + '/create';
export const PROFILE_ROUTE = PREFIX + '/profile';
export const EDIT_PERSONA_ROUTE = PREFIX + '/edit/:id';
export const CALL_PERSONA_ROUTE = PREFIX + '/call/:id';
export const EMBED_ROUTE = PREFIX + '/embed/:id';
export const EVENT_ROUTE = PREFIX + '/event/:id';
export const EVENT2_ROUTE = PREFIX + '/event2';

export const LOGIN_ROUTE = '/login';

export const TAVUS_PREFIX = PREFIX + '/demo';
export const TAVUS_EMBED_ROUTE = TAVUS_PREFIX + '/embed/:id';

export const AVATARS_PREFIX = PREFIX + '/avatars';
export const AVATARS_EMBED_ROUTE = AVATARS_PREFIX + '/embed/:id';
export const AVATARS_MAIN_ROUTE = AVATARS_PREFIX;
export const AVATARS_CALL_ROUTE = AVATARS_PREFIX + '/call';
export const AVATARS_CREATE_PERSONA_ROUTE = AVATARS_PREFIX + '/create';
export const AVATARS_EDIT_PERSONA_ROUTE = AVATARS_PREFIX + '/edit/:id';
