import { create } from 'zustand';
import { personaApi } from '../api';
import {
  ICreatePersonaReq,
  IKnowledgeReq,
  IPersona,
  IPersonaFormik,
  IPersonaList,
  IStarterQuestionReq,
  Visibility
} from './personaTypes';
import {
  DEFAULT_AVATAR,
  DEFAULT_VOICE,
  getErrorMessageV2,
  showError,
  TApiKeys
} from '../../../shared';
import { useAuthStore } from '../../../stores/useAuthStore';

export const initialPersona: IPersonaFormik = {
  name: '',
  avatarId: DEFAULT_AVATAR,
  voice: DEFAULT_VOICE,
  description: '',
  visibility: Visibility.PRIVATE,
  definition: '',
  greeting: '',
  knowledgeSources: [],
  starterQuestions: [],
  callToActions: [],
  introMessages: [],
  background: '',
  model: 'gpt-4o'
};

type State = {
  detailedPersona: IPersona | null;
  personas: IPersonaList[];
  isPersonaCreating: boolean;
  isPersonasLoading: boolean;
  isPersonaDeleting: boolean;
  personaShareModal: IPersonaList | IPersona | null;
  activePersona: IPersonaList | null;
  personaPrivacyModal: string | null;
  personaEmbedModal: string | null;
  createAgentModal: boolean;
};

type Actions = {
  getDetailedPersona: (
    personaId: string | null,
    keys?: TApiKeys,
    showErrorToast?: boolean
  ) => Promise<boolean>;
  fetchPersonas: () => Promise<void>;
  addPersona: (personaData: ICreatePersonaReq) => Promise<IPersona | undefined>;
  updatePersona: (
    persona: IPersona,
    personaData: ICreatePersonaReq
  ) => Promise<void>;
  deletePersona: (personaId: string) => Promise<void>;
  setPersonaShareModal: (persona: IPersonaList | IPersona | null) => void;
  updateVisibility: (
    personaId: string,
    visibility: Visibility
  ) => Promise<void>;
  setActivePersona: (id: string) => void;
  togglePersonaPrivacyModal: (personaId: string | null) => void;
  togglePersonaEmbedModal: (personaId: string | null) => void;
  setCreateAgentModal: (value: boolean) => void;
};

export const personaStore = create<
  State & Actions,
  [['zustand/persist', unknown]]
>((set, get) => ({
  personas: [],
  createAgentModal: false,
  detailedPersona: null,
  currentPersona: null,
  isPersonasLoading: false,
  isPersonaCreating: false,
  isPersonaDeleting: false,
  personaShareModal: null,
  personaPrivacyModal: null,
  personaEmbedModal: null,
  activePersona: null,
  togglePersonaPrivacyModal: (personaId) => {
    set({ personaPrivacyModal: personaId });
  },
  togglePersonaEmbedModal: (personaId) => {
    set({ personaEmbedModal: personaId });
  },

  setActivePersona: (id) => {
    const persona = get().personas.find((p) => p.id === id);

    if (persona) {
      set({ activePersona: persona });
    }
  },
  setPersonaShareModal: (persona) => {
    set({ personaShareModal: persona ?? null });
  },
  updateVisibility: async (personaId, visibility) => {
    try {
      const updatedPersona = await personaApi.update(personaId, {
        visibility
      });

      set((state) => ({
        personaShareModal:
          state.personaShareModal &&
          state.personaShareModal.id === updatedPersona.id
            ? updatedPersona
            : state.personaShareModal
      }));

      await get().fetchPersonas();
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to update persona status. Please try again later.'
      );
      showError(message);
    }
  },
  updatePersona: async (
    persona,
    { starterQuestions, knowledgeSources, ...personaData }
  ) => {
    set({ isPersonaCreating: true });
    try {
      knowledgeSources = (knowledgeSources ?? []) as IKnowledgeReq[];
      persona.knowledgeSources = persona.knowledgeSources ?? [];
      starterQuestions = (starterQuestions ?? []) as IStarterQuestionReq[];
      persona.starterQuestions = persona.starterQuestions ?? [];
      let promises: Promise<unknown>[] = [];
      if (
        !(
          knowledgeSources.length === persona.knowledgeSources.length &&
          persona.knowledgeSources.every((knowledge) =>
            knowledgeSources!.some(
              (newKnowledge) =>
                newKnowledge.assetId === knowledge.assetId &&
                newKnowledge.type === knowledge.type
            )
          )
        )
      ) {
        promises = [
          ...persona.knowledgeSources.map((knowledge) =>
            personaApi.deleteKnowledgeSource(persona.id, knowledge.id)
          ),
          ...knowledgeSources.map((knowledge) =>
            personaApi.addKnowledgeSource(persona.id, knowledge)
          )
        ];
      }

      if (
        !(
          starterQuestions.length === persona.starterQuestions.length &&
          starterQuestions.every((question) =>
            starterQuestions!.some(
              (newQuestion) => newQuestion.question === question.question
            )
          )
        )
      ) {
        promises = promises.concat([
          ...persona.starterQuestions.map((question) =>
            personaApi.deleteStartQuestion(persona.id, question.id)
          ),
          ...starterQuestions.map(({ question }) =>
            personaApi.addStarterQuestion(persona.id, question)
          )
        ]);
      }

      await Promise.all(promises);

      await personaApi.update(persona.id, personaData);

      await get().fetchPersonas();
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to update a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaCreating: false });
    }
  },
  getDetailedPersona: async (personaId, keys, showErrorToast = true) => {
    if (personaId === null) {
      set({ detailedPersona: null });
      return true;
    }
    try {
      const persona = await personaApi.getById(personaId, keys);
      set({ detailedPersona: persona });
      return true;
    } catch (error) {
      if (showErrorToast) {
        const message = getErrorMessageV2(
          error,
          'There was a problem when we tried to get persona details. Please try again later.'
        );
        showError(message);
      }
      return false;
    }
  },
  fetchPersonas: async () => {
    set({ isPersonasLoading: true });
    try {
      const personas = await personaApi.fetchAll();
      set({ personas });
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to get personas list. Please try again later.'
      );
      showError(message);
      set({
        personas: []
      });
    } finally {
      set({ isPersonasLoading: false });
    }
  },
  addPersona: async (personaData) => {
    set({
      isPersonaCreating: true
    });
    try {
      const data = await personaApi.create(personaData);

      await get().fetchPersonas();

      return data;
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to create a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({
        isPersonaCreating: false
      });
    }
  },
  deletePersona: async (personaId) => {
    set({ isPersonaDeleting: true });
    try {
      await personaApi.delete(personaId);

      const personas = [...get().personas];
      const indexToDelete = personas.findIndex((p) => p.id === personaId);
      personas.splice(indexToDelete, 1);

      set({ personas });
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to delete a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaDeleting: false });
    }
  },
  setCreateAgentModal: (value: boolean) => {
    set({ createAgentModal: value });
  }
}));
