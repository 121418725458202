import React, { MutableRefObject, useRef } from 'react';
import { useUploadFile } from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/hooks/useUploadFile';
import { Loader } from 'shared/ui';
import { getErrorMessage } from 'shared/lib';
import { showError } from 'shared/model';
import { LuUploadCloud } from 'react-icons/lu';

type AddCustomVideoProps = {
  inputId: string;
  onUpload: (src: string) => void;
};

const AddCustomVideo = ({ inputId, onUpload }: AddCustomVideoProps) => {
  const uploadref: MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement | null>(null);

  const { error, uploadFile, isLoading } = useUploadFile();

  const handleUpload = async (files: FileList) => {
    try {
      const data = await uploadFile(files[0]);
      if (!data) return;
      if (data.mediaLink) {
        onUpload(data.mediaLink);
      }
    } catch (error) {
      const message = getErrorMessage(
        error,
        'There was a problem when we tried to upload file. Please try again later.'
      );
      showError(message);
    }
  };

  return (
    <div>
      <label className="block cursor-pointer" htmlFor={inputId}>
        <div className="inline-flex items-center justify-center px-2.5 py-2 sm:py-2.5 border  shadow-sm text-sm font-medium rounded-lg group relative bg-white w-40 ag-text-neutral-700">
          <LuUploadCloud className={'ag-mr-2'} />
          {isLoading ? <Loader size={20} /> : 'Upload video'}
        </div>
        <input
          ref={uploadref}
          accept=".mp4"
          className="hidden"
          type="file"
          id={inputId}
          name={inputId}
          onChange={(e) => handleUpload(e.target.files as FileList)}
        />
      </label>
      {error && error}
    </div>
  );
};

export default AddCustomVideo;
