import { cn, logger } from 'shared/lib';
import {
  Fragment,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react';

import { BiX } from 'react-icons/bi';
import { IoChevronUp } from 'react-icons/io5';
import { FaMicrophone } from 'react-icons/fa6';
import { IoMdCheckmark } from 'react-icons/io';

import Divider from 'components/Divider';
import { videoCallStore } from 'features/livekit-call';

type Props = {
  isEvent?: boolean;
  isRecordingMic: boolean;
  stopMicrophone: () => void;
  startMicrophone: () => void;
};

export const TalkingMode = ({
  isEvent = false,
  isRecordingMic,
  stopMicrophone,
  startMicrophone
}: Props) => {
  const {
    isTalkingModeModalOpen,
    toggleTalkingModeModal,
    isListening,
    toggleListening
  } = videoCallStore();

  const isRecordingMicRef = useRef(isRecordingMic);
  const isListeningRef = useRef(isListening);

  useEffect(() => {
    isListeningRef.current = isListening;
  }, [isListening]);

  useEffect(() => {
    isRecordingMicRef.current = isRecordingMic;
  }, [isRecordingMic]);

  const handleToggleTalkingMode = () => {
    toggleListening();
    toggleTalkingModeModal();
  };

  const handleKeyDown = (event: globalThis.KeyboardEvent) => {
    if (!isEvent) return;

    if (
      (event.key === 'Alt' || event.altKey) &&
      (event.key === 'Ctrl' || event.ctrlKey)
    ) {
      if (isListeningRef.current) {
        if (!isRecordingMicRef.current) {
          startMicrophone();
        } else {
          stopMicrophone();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!isListening) {
      stopMicrophone();
    } else {
      startMicrophone();
    }
  }, [isListening]);

  return (
    <>
      <div className="ag-flex ag-items-center ag-rounded-full ag-bg-neutral-100 ag-border ag-border-neutral-300 ag-relative">
        {isListening ? (
          <button
            className="ag-border ag-rounded-full ag-border-neutral-300 ag-size-10 ag-relative -ag-left-[2px] ag-bg-neutral-200 ag-overflow-hidden"
            onClick={() =>
              isRecordingMic ? stopMicrophone() : startMicrophone()
            }
          >
            {isRecordingMic ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 56 56"
                fill="none"
                className="ag-size-full"
              >
                <g clipPath="url(#clip0_1358_2011)">
                  <circle
                    cx="27.9999"
                    cy="28.0001"
                    r="19.0556"
                    fill="#525252"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0ZM37.3516 29.2572C36.9929 31.5247 35.8152 33.5345 34.1317 34.9734C32.4793 36.3856 30.3361 37.2408 28 37.2408C25.6639 37.2408 23.5207 36.3855 21.8683 34.9734C20.1848 33.5345 19.0071 31.5247 18.6484 29.2572C18.5313 28.5001 17.8225 27.9813 17.0655 28.0984C16.3084 28.2156 15.7896 28.9243 15.9067 29.6814C16.3727 32.6272 17.8923 35.2295 20.0622 37.084C21.9627 38.7082 24.359 39.7645 26.9816 39.9836V42.5644H23.2146C22.4455 42.5644 21.822 43.1879 21.822 43.9569C21.822 44.7261 22.4454 45.3496 23.2146 45.3496H33.5339C34.3031 45.3496 34.9265 44.7261 34.9265 43.9569C34.9265 43.1878 34.303 42.5644 33.5339 42.5644H29.767V39.8976C32.0963 39.5566 34.2197 38.5524 35.9378 37.0841C38.1077 35.2296 39.6274 32.6273 40.0933 29.6815C40.2104 28.9244 39.6916 28.2157 38.9345 28.0985C38.1776 27.9813 37.4687 28.5001 37.3516 29.2572ZM28 10.6505C26.1292 10.6505 24.4296 11.4148 23.1982 12.6462C21.9669 13.8775 21.2026 15.5772 21.2026 17.4479V27.778C21.2026 29.6487 21.967 31.3485 23.1982 32.5798C24.4296 33.8111 26.1294 34.5754 28.0001 34.5754C29.8709 34.5754 31.5705 33.8111 32.8019 32.5798C34.0332 31.3484 34.7975 29.6486 34.7975 27.778V17.4479C34.7975 15.5772 34.0332 13.8775 32.8019 12.6462C31.5704 11.4148 29.8708 10.6505 28 10.6505Z"
                    fill="#E5E5E5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1358_2011">
                    <rect width="56" height="56" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 56 56"
                fill="none"
                className="ag-size-full"
              >
                <g clipPath="url(#clip0_1358_2038)">
                  <circle cx="28.0002" cy="28.0001" r="23.8" fill="#DC2626" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0ZM37.3516 29.2616C36.9929 31.5292 35.8152 33.539 34.1317 34.9779C32.4793 36.39 30.3361 37.2453 28 37.2453C26.6296 37.2453 25.3258 36.9509 24.1484 36.4225L22.079 38.4918C23.5562 39.3146 25.2177 39.8407 26.9816 39.9879V42.5688H23.2146C22.4455 42.5688 21.822 43.1923 21.822 43.9613C21.822 44.7304 22.4454 45.354 23.2146 45.354H33.5339C34.3031 45.354 34.9265 44.7304 34.9265 43.9613C34.9265 43.1922 34.303 42.5688 33.5339 42.5688H29.767V39.902C32.0963 39.5609 34.2197 38.5568 35.9378 37.0885C38.1077 35.234 39.6274 32.6317 40.0933 29.6859C40.2104 28.9288 39.6916 28.2201 38.9345 28.1029C38.1775 27.9858 37.4687 28.5047 37.3516 29.2616ZM14.9715 41.3702L43.1432 13.1985C43.7271 12.6145 43.7271 11.6678 43.1432 11.084C42.5592 10.5 41.6125 10.5 41.0286 11.084L34.796 17.3166C34.7599 15.4996 34.0032 13.8522 32.8018 12.6508C31.5704 11.4193 29.8708 10.655 28 10.655C26.1292 10.655 24.4296 11.4193 23.1981 12.6506C21.9668 13.882 21.2025 15.5817 21.2025 17.4524V27.7825C21.2025 28.7045 21.3883 29.585 21.7241 30.3884L19.733 32.3795C19.1986 31.427 18.8248 30.3755 18.6485 29.2615C18.5314 28.5044 17.8227 27.9857 17.0656 28.1028C16.3085 28.22 15.7897 28.9287 15.9068 29.6858C16.178 31.4004 16.8062 32.9985 17.7119 34.4005L12.8569 39.2555C12.273 39.8394 12.273 40.7862 12.8569 41.3701C13.4408 41.9542 14.3875 41.9542 14.9715 41.3702ZM34.7974 25.7735L26.2266 34.3443C26.7924 34.498 27.387 34.5801 28 34.5801C29.8708 34.5801 31.5704 33.8158 32.8018 32.5843C34.0331 31.3531 34.7974 29.6533 34.7974 27.7826V25.7735Z"
                    fill="#E5E5E5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1358_2038">
                    <rect width="56" height="56" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </button>
        ) : (
          <button
            className={cn(
              'ag-size-10 ag-p-2 ag-border ag-bg-neutral-200 ag-border-neutral-300 bg-neutral-200 ag-text-neutral-600 ag-rounded-full ag-relative -ag-left-[1px] ag-flex ag-items-center ag-justify-center',
              isRecordingMic && 'ag-text-primary-600 ag-bg-primary-100'
            )}
            onMouseDown={startMicrophone}
            onMouseUp={stopMicrophone}
            onTouchStart={startMicrophone}
            onTouchEnd={stopMicrophone}
          >
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="currentColor"
              stroke="none"
              className=""
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 0.5C5.55695 0.501389 4.41317 0.975941 3.56955 1.81955C2.90411 2.485 2.46829 3.33722 2.31321 4.25307C2.24404 4.66157 2.58612 5 3.00044 5C3.41441 5 3.74078 4.6593 3.84315 4.25819C3.97527 3.74052 4.2445 3.26286 4.62868 2.87868C5.19129 2.31607 5.95435 2 6.75 2C7.54565 2 8.30871 2.31607 8.87132 2.87868C9.2555 3.26286 9.52473 3.74052 9.65685 4.25819C9.75922 4.6593 10.0856 5 10.4996 5C10.9139 5 11.256 4.66157 11.1868 4.25307C11.0317 3.33722 10.5959 2.485 9.93045 1.81955C9.08683 0.975941 7.94305 0.501389 6.75 0.5Z"
                fill="currentColor"
              />
              <path
                d="M12.7504 21.5H9.42037C8.66791 21.4998 7.94299 21.2169 7.38937 20.7073L0.489368 14.3577C0.328266 14.2105 0.201212 14.0298 0.117057 13.8284C0.0329023 13.627 -0.00632925 13.4097 0.00209545 13.1916C0.0105201 12.9735 0.0663987 12.7598 0.165838 12.5655C0.265277 12.3712 0.405886 12.2009 0.577868 12.0665C0.872508 11.8506 1.23372 11.7453 1.5982 11.7691C1.96268 11.7928 2.30719 11.944 2.57137 12.1962L5.25037 14.6427V5C5.25037 4.60218 5.4084 4.22064 5.68971 3.93934C5.97101 3.65804 6.35254 3.5 6.75037 3.5C7.14819 3.5 7.52972 3.65804 7.81103 3.93934C8.09233 4.22064 8.25037 4.60218 8.25037 5V10.25C8.25037 9.85218 8.4084 9.47064 8.68971 9.18934C8.97101 8.90804 9.35254 8.75 9.75037 8.75C10.1482 8.75 10.5297 8.90804 10.811 9.18934C11.0923 9.47064 11.2504 9.85218 11.2504 10.25V11C11.2504 10.6022 11.4084 10.2206 11.6897 9.93934C11.971 9.65804 12.3525 9.5 12.7504 9.5C13.1482 9.5 13.5297 9.65804 13.811 9.93934C14.0923 10.2206 14.2504 10.6022 14.2504 11V11.75C14.2504 11.3522 14.4084 10.9706 14.6897 10.6893C14.971 10.408 15.3525 10.25 15.7504 10.25C16.1482 10.25 16.5297 10.408 16.811 10.6893C17.0923 10.9706 17.2504 11.3522 17.2504 11.75V17C17.2504 18.1935 16.7763 19.3381 15.9324 20.182C15.0884 21.0259 13.9438 21.5 12.7504 21.5Z"
                fill="currentColor"
              />
            </svg>
          </button>
        )}
        <button
          onClick={toggleTalkingModeModal}
          className="ag-text-neutral-600 ag-pl-1.5 ag-pr-3"
        >
          <IoChevronUp />
        </button>
      </div>

      <Transition show={isTalkingModeModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="ag-relative ag-z-50"
          onClose={toggleTalkingModeModal}
        >
          {!isEvent && (
            <TransitionChild
              as={Fragment}
              enter="ag-ease-out ag-duration-300"
              enterFrom="ag-opacity-0"
              enterTo="ag-opacity-100"
              leave="ag-ease-in ag-duration-200"
              leaveFrom="ag-opacity-100"
              leaveTo="ag-opacity-0"
            >
              <div className="ag-fixed ag-inset-0 ag-bg-neutral-600 ag-bg-opacity-50 ag-transition-opacity" />
            </TransitionChild>
          )}
          <div
            className={cn(
              `ag-fixed ag-z-10 sm:ag-flex ag-scrollbar-none`,
              isEvent
                ? 'ag-bottom-[8.6rem] ag-left-2'
                : 'ag-w-full ag-bottom-0 ag-left-0 sm:ag-inset-0 '
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ag-ease-out ag-duration-300"
              enterFrom="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
              enterTo="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leave="ag-ease-in ag-duration-200"
              leaveFrom="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leaveTo="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
            >
              <DialogPanel
                className={cn(
                  'ag-relative ag-transform ag-rounded-lg ag-bg-white ag-text-left ag-shadow-xl ag-transition-all sm:ag-w-[255px] sm:ag-m-auto ag-scrollbar-none ag-border ag-border-neutral-200'
                )}
              >
                <BiX
                  className="ag-absolute ag-top-3 ag-right-3 ag-text-3xl ag-text-neutral-gray ag-cursor-pointer ag-z-[99999]"
                  onClick={toggleTalkingModeModal}
                />
                <div className="ag-p-4 ag-font-semibold">Microphone</div>
                <Divider />
                <div className="ag-px-2 ag-py-2 ag-text-sm ag-text-neutral-700 ag-space-y-2">
                  <div
                    className={cn(
                      'ag-flex ag-items-center ag-justify-between ag-p-2 ag-rounded-md',
                      isListening
                        ? 'ag-bg-neutral-100 ag-cursor-default'
                        : 'hover:ag-bg-neutral-100 ag-cursor-pointer'
                    )}
                    onClick={!isListening ? handleToggleTalkingMode : () => {}}
                  >
                    <div className="ag-flex ag-items-center ag-gap-2">
                      <FaMicrophone />
                      <div>Always listening</div>
                    </div>
                    {isListening && (
                      <IoMdCheckmark className="ag-text-lg ag-text-primary-600" />
                    )}
                  </div>

                  <div
                    className={cn(
                      'ag-flex ag-items-center ag-justify-between ag-p-2 ag-rounded-md',
                      !isListening
                        ? 'ag-bg-neutral-100 ag-cursor-default'
                        : 'hover:ag-bg-neutral-100 ag-cursor-pointer'
                    )}
                    onClick={isListening ? handleToggleTalkingMode : () => {}}
                  >
                    <div
                      className={cn(
                        'ag-flex ag-items-center ag-gap-2 ag-text-sm'
                      )}
                    >
                      <svg
                        width="18"
                        height="22"
                        viewBox="0 0 18 22"
                        fill="currentColor"
                        stroke="none"
                        className="ag-size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.75 0.5C5.55695 0.501389 4.41317 0.975941 3.56955 1.81955C2.90411 2.485 2.46829 3.33722 2.31321 4.25307C2.24404 4.66157 2.58612 5 3.00044 5C3.41441 5 3.74078 4.6593 3.84315 4.25819C3.97527 3.74052 4.2445 3.26286 4.62868 2.87868C5.19129 2.31607 5.95435 2 6.75 2C7.54565 2 8.30871 2.31607 8.87132 2.87868C9.2555 3.26286 9.52473 3.74052 9.65685 4.25819C9.75922 4.6593 10.0856 5 10.4996 5C10.9139 5 11.256 4.66157 11.1868 4.25307C11.0317 3.33722 10.5959 2.485 9.93045 1.81955C9.08683 0.975941 7.94305 0.501389 6.75 0.5Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12.7504 21.5H9.42037C8.66791 21.4998 7.94299 21.2169 7.38937 20.7073L0.489368 14.3577C0.328266 14.2105 0.201212 14.0298 0.117057 13.8284C0.0329023 13.627 -0.00632925 13.4097 0.00209545 13.1916C0.0105201 12.9735 0.0663987 12.7598 0.165838 12.5655C0.265277 12.3712 0.405886 12.2009 0.577868 12.0665C0.872508 11.8506 1.23372 11.7453 1.5982 11.7691C1.96268 11.7928 2.30719 11.944 2.57137 12.1962L5.25037 14.6427V5C5.25037 4.60218 5.4084 4.22064 5.68971 3.93934C5.97101 3.65804 6.35254 3.5 6.75037 3.5C7.14819 3.5 7.52972 3.65804 7.81103 3.93934C8.09233 4.22064 8.25037 4.60218 8.25037 5V10.25C8.25037 9.85218 8.4084 9.47064 8.68971 9.18934C8.97101 8.90804 9.35254 8.75 9.75037 8.75C10.1482 8.75 10.5297 8.90804 10.811 9.18934C11.0923 9.47064 11.2504 9.85218 11.2504 10.25V11C11.2504 10.6022 11.4084 10.2206 11.6897 9.93934C11.971 9.65804 12.3525 9.5 12.7504 9.5C13.1482 9.5 13.5297 9.65804 13.811 9.93934C14.0923 10.2206 14.2504 10.6022 14.2504 11V11.75C14.2504 11.3522 14.4084 10.9706 14.6897 10.6893C14.971 10.408 15.3525 10.25 15.7504 10.25C16.1482 10.25 16.5297 10.408 16.811 10.6893C17.0923 10.9706 17.2504 11.3522 17.2504 11.75V17C17.2504 18.1935 16.7763 19.3381 15.9324 20.182C15.0884 21.0259 13.9438 21.5 12.7504 21.5Z"
                          fill="currentColor"
                        />
                      </svg>

                      <div>Tap down to talk</div>
                    </div>
                    {!isListening && (
                      <IoMdCheckmark className="ag-text-lg ag-text-primary-600" />
                    )}
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
