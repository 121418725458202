import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { authService } from 'services/auth.service';

import {
  STT_LOCALES,
  TApiKeys,
  ITMessageLivekit,
  getErrorMessageV2,
  showError
} from 'shared';
import { IPersona, personaApi } from 'entities/persona';
import { DatachannelMessageType } from './types';
import { AxiosError } from 'axios';

export type VideoCallProps = {
  isVideoStarted: boolean; // backend video track started
  isConnected: boolean; // has video track
  isFullScreen: boolean;
  persona: IPersona | null;
  personaId: string | null;
  dataChannelMessage:
    | ({ type: DatachannelMessageType } & { [k: string]: any })
    | null;
  secrets: {
    url: string;
    userToken: string;
    botIdentity: string;
    userIdentity: string;
  } | null;
  messages: ITMessageLivekit[];
  sttLocale: typeof STT_LOCALES[number];
  isCallLoading: boolean;
  withCaptions: boolean;
  withTapToTalk: boolean;
  isTalkingModeModalOpen: boolean;
  isListening: boolean;
};

type Actions = {
  setLocale: (locale: VideoCallProps['sttLocale']) => void;
  setVideoStarted: (isVideoStarted: boolean) => void;
  toggleIsConnected: () => void;
  toggleFullScreen: () => void;
  sendDataChannel: (msg: VideoCallProps['dataChannelMessage']) => void;
  setMessages: (messages: VideoCallProps['messages']) => void;
  addMessage: (message: Omit<ITMessageLivekit, 'language'>) => void;
  getLivekitToken: (personaId: string, keys?: TApiKeys) => Promise<boolean>;
  endCall: () => void;
  toggleCaptions: () => void;
  toggleTapToTalk: () => void;
  setPersona: (personaId: string) => void;
  toggleTalkingModeModal: () => void;
  toggleListening: () => void;
};

export const videoCallStore = create<
  VideoCallProps & Actions,
  [['zustand/persist', unknown]]
>(
  persist<
    VideoCallProps & Actions,
    [],
    [],
    Pick<VideoCallProps, 'secrets' | 'sttLocale'>
  >(
    (set, get) => ({
      persona: null,
      personaId: null,
      setPersona: (personaId) => set({ personaId }),
      isCallLoading: false,
      sttLocale: 'en-US',
      setLocale: (locale) => set({ sttLocale: locale }),
      dataChannelMessage: null,
      sendDataChannel: (msg) => set({ dataChannelMessage: msg }),
      messages: [],
      setMessages: (messages) => set({ messages }),
      addMessage: (message) =>
        set((state) => ({
          messages: state.messages.concat([
            { ...message, language: get().sttLocale }
          ])
        })),
      isFullScreen: false,
      endCall: () => {
        set({
          persona: null,
          secrets: null,
          isVideoStarted: false,
          messages: []
        });
      },
      withCaptions: true,
      toggleCaptions: () => {
        set((state) => ({ withCaptions: !state.withCaptions }));
      },
      withTapToTalk: false,
      toggleTapToTalk: () => {
        set((state) => ({ withTapToTalk: !state.withTapToTalk }));
      },
      toggleFullScreen: () =>
        set((state) => ({ isFullScreen: !state.isFullScreen })),
      isTalkingModeModalOpen: false,
      toggleTalkingModeModal: () =>
        set((state) => ({
          isTalkingModeModalOpen: !state.isTalkingModeModalOpen
        })),
      isListening: true,
      toggleListening: () =>
        set((state) => ({ isListening: !state.isListening })),
      // livekit only
      isVideoStarted: false,
      setVideoStarted: (isVideoStarted) => set({ isVideoStarted }),
      secrets: null,
      isConnected: false,
      toggleIsConnected: () =>
        set((state) => ({ isConnected: !state.isConnected })),
      getLivekitToken: async (personaId, keys) => {
        try {
          set({ isCallLoading: true });

          const persona = await personaApi.getById(personaId, keys);

          const {
            liveKitSeverUrl,
            liveKitChatBotIdentity,
            liveKitUserToken,
            liveKitUserIdentity
          } = await authService.getLivekitToken(
            {
              personaId: persona.id
            },
            keys
          );

          set({
            persona,
            secrets: {
              url: liveKitSeverUrl,
              userToken: liveKitUserToken,
              botIdentity: liveKitChatBotIdentity,
              userIdentity: liveKitUserIdentity
            }
          });

          return true;
        } catch (e) {
          let message = '';
          if (e instanceof AxiosError && e.response?.status === 401) {
            message = 'You must login to call an agent';
          } else {
            message = getErrorMessageV2(e, 'Unable to start a call.', true);
          }
          showError(message);
          return false;
        } finally {
          set({ isCallLoading: false });
        }
      }
    }),
    {
      name: 'video-call-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        secrets: state.secrets,
        sttLocale: state.sttLocale
      })
    }
  )
);
