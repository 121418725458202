import {
  DailyAudio,
  DailyProvider,
  DailyVideo,
  useParticipantIds
} from '@daily-co/daily-react';
import { conversationStore } from 'entities/tavus/conversation';
import {
  DAILY_CALL_STATES,
  events,
  transcriptionEvents
} from 'features/daily-call/model';
import { dailyCallStore } from 'features/daily-call/model/dailyCallStore';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { MAIN_ROUTE } from 'shared/model';
import { Loader } from 'shared/ui';
import { TavusControlBar } from 'widgets/call-controls';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { logger, useQueryParams } from 'shared/lib';
import { GreenScreenVideo } from 'features/green-screen-video';
import { Captions } from 'widgets/captions';
import { ToggleCaptions } from 'features/toggle-captions';

export const TavusConference = () => {
  const { currentConversation, endConversation } = conversationStore();

  const {
    startHairCheck,
    callObject,
    handleNewMeetingState,
    currentState,
    startLeavingCall,
    joinCall,
    addMessage,
    messages,
    withCaptions,
    toggleCaptions
  } = dailyCallStore();

  useEffect(() => {
    if (currentConversation) {
      startHairCheck(currentConversation.conversation_url);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (!callObject) return;

    handleNewMeetingState();
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    callObject.on('transcription-message', (message) => addMessage(message));

    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
      transcriptionEvents.forEach((event) => callObject.off(event, () => {}));
    };
  }, [callObject]);

  const nav = useNavigate();

  const handleEndCall = () => {
    endConversation(messages);
    startLeavingCall();
    nav(MAIN_ROUTE);
  };

  useEffect(() => {
    return () => {
      handleEndCall();
    };
  }, []);

  useEffect(() => {
    if (currentState === DAILY_CALL_STATES.STATE_HAIRCHECK) {
      if (currentConversation) {
        joinCall(currentConversation?.conversation_url);
      }
    }
    if (currentState === DAILY_CALL_STATES.STATE_ERROR) {
      handleEndCall();
    }
  }, [currentState]);

  if (!callObject) return null;
  return (
    <div
      className={`ag-bg-neutral-900 ag-h-screen ag-flex ag-items-center ag-justify-center ag-relative`}
    >
      <DailyProvider callObject={callObject}>
        {[
          DAILY_CALL_STATES.STATE_JOINING,
          DAILY_CALL_STATES.STATE_JOINED,
          DAILY_CALL_STATES.STATE_ERROR
        ].includes(currentState) ? (
          <>
            <Video />

            <TavusControlBar onCallEnd={handleEndCall} />
            <div className="ag-flex ag-items-center ag-gap-6 ag-absolute ag-top-3.5 ag-right-3.5">
              <ToggleCaptions
                withCaptions={withCaptions}
                toggleCaptions={toggleCaptions}
              />
            </div>
          </>
        ) : (
          <Loader size={96} />
        )}

        <DailyAudio />
      </DailyProvider>
    </div>
  );
};

const Video = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const ref = useRef<HTMLVideoElement>(null);
  const currentPersona = tavusPersonaStore((state) => state.currentPersona);
  return (
    <div className="ag-relative ag-w-full ag-h-full ag-pb-[4.5rem] ag-flex ag-justify-center ">
      {remoteParticipantIds.length > 0 ? (
        remoteParticipantIds.map((id) => (
          <React.Fragment key={id}>
            <DailyVideo
              className={'ag-absolute -ag-z-10'}
              ref={ref}
              automirror
              sessionId={id}
              type={'video'}
              playableStyle={{ width: '100%', height: '100%' }}
              fit="cover"
            />
            {currentPersona?.custom_background?.includes('video;') && (
              <video
                muted
                loop
                autoPlay
                className={
                  'ag-absolute ag-object-cover ag-h-[calc(100%-4.5rem)] ag-w-full'
                }
                src={currentPersona?.custom_background?.replace('video;', '')}
              />
            )}
            <GreenScreenVideo
              background={currentPersona?.custom_background}
              videoRef={ref}
              classNames={
                'ag-relative ag-w-full ag-h-full ag-inset-0 ag-object-top'
              }
            />
          </React.Fragment>
        ))
      ) : (
        <div
          className={
            'ag-w-full ag-h-full ag-flex ag-justify-center ag-items-center ag-pb-5 ag-flex-col'
          }
        >
          <Loader size={96} />
        </div>
      )}
    </div>
  );
};
