import { useEffect, useMemo, useRef } from 'react';

type CallbackFunction = (...args: any[]) => void;

const debounce = (func: CallbackFunction, delay: number): CallbackFunction => {
  let timer: NodeJS.Timeout | null = null;
  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func(...args);
    }, delay);
  };
};

export const useDebounce = (
  callback: CallbackFunction,
  timeout: number
): CallbackFunction => {
  const callbackRef = useRef<CallbackFunction>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: any[]) => {
      callbackRef.current(...args);
    };

    return debounce(func, timeout);
  }, [timeout]);

  return debouncedCallback;
};
