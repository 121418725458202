import { create } from 'zustand';
import { replicaApi } from '../api';
import { toast } from 'react-toastify';
import { Replica } from './replicaTypes';
import { userStore } from 'entities/user';

type State = {
  replicas: Replica[];
  replicasLoading: boolean;
  currentReplica: Replica | null;
  total: number;
  page: number;
  size: number;
  pages: number;
};
type Actions = {
  fetchReplicas: () => Promise<void>;
  fetchOne: (id: string) => Promise<Replica | undefined>;
  getReplicaById: (id: string) => Replica | undefined;
  setCurrentReplica: (replica: Replica | null) => void;
};

type Store = State & Actions;

export const replicaStore = create<Store>((set, get) => ({
  replicas: [],
  replicasLoading: false,
  currentReplica: null,
  total: 0,
  page: 1,
  size: 12,
  pages: 1,
  setCurrentReplica: (replica) => {
    set({ currentReplica: replica });
  },
  fetchReplicas: async () => {
    set({ replicasLoading: true });
    const userId = userStore.getState().user?.id;
    try {
      const { items, total, page, size, pages } = await replicaApi.fetchAll(
        userId!
      );

      set({ replicas: items, total, page, size, pages });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replicas list. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  fetchOne: async (id: string) => {
    set({ replicasLoading: true });
    try {
      return await replicaApi.fetch(id);
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replica details. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  getReplicaById: (id) => {
    const allReplicas = get().replicas;

    return allReplicas.find((a) => a.replica_id === id);
  }
}));
