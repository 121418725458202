import { create } from 'zustand';
import { Conversation } from './conversationTypes';
import { toast } from 'react-toastify';
import { conversationApi } from '../api';
import { ITavusPersona } from 'entities/tavus/persona';
import { getErrorMessageV2, logger } from 'shared/lib';
import { ITMessageDaily } from 'shared/model';

type State = {
  currentConversation: Conversation | null;
  conversationLoading: boolean;
  callIsFinishing: boolean;
};

type Action = {
  startConversation: (
    currentPersona: ITavusPersona | null,
    key?: string
  ) => Promise<void>;
  endConversation: (messages: ITMessageDaily[]) => Promise<void>;
};

type Store = State & Action;

export const conversationStore = create<Store>((set, get) => ({
  currentConversation: null,
  conversationLoading: false,
  startConversation: async (currentPersona, key) => {
    if (!currentPersona) {
      toast.error('Please select a persona to start the conversation.');
      throw new Error('No persona selected.');
    }
    set({ conversationLoading: true });
    try {
      const conversation = await conversationApi.createConversation(
        currentPersona.id!,
        key
      );

      set({ currentConversation: conversation });
    } catch (error) {
      // const message = getErrorMessageV2(
      //   error,
      //   'Could not start the conversation.',
      //   true
      // );
      // toast.error(message);
      toast.error("Couldn't start the conversation. Please try again later.");
      throw error;
    } finally {
      set({ conversationLoading: false });
    }
  },
  callIsFinishing: false,
  endConversation: async (messages) => {
    if (get().callIsFinishing) {
      logger.error('Last end conversation request is not finished yet.');
      return;

      // TODO: Get rid of second request (in return of TavusConvference)
    }
    set({ callIsFinishing: true });
    const conversationId = get().currentConversation?.conversation_id;

    if (!conversationId) {
      toast.error('No conversation to end.');
      return;
    }

    try {
      await conversationApi.endConversation(conversationId, messages);

      set({ currentConversation: null });
    } catch (error) {
      toast.error("Couldn't end the conversation. Please try again later.");
    } finally {
      set({ callIsFinishing: false });
    }
  }
}));
