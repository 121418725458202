import { FormikProps } from 'formik';
import { IPersona, IPersonaFormik } from 'entities/persona';
import { FC, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import Divider from 'components/Divider';
import { TalkingPhotoListModal } from 'features/talking-photo-list-modal';
import { avatarStore } from 'entities/avatar';
import { OutlinedButton } from 'shared/ui';
import { SlRefresh } from 'react-icons/sl';
import useVoiceStore from 'stores/useVoiceStore';
import { VoiceSelectButton } from 'features/voice-select-button';

interface IProps {
  formik: FormikProps<IPersonaFormik>;
  persona: IPersona;
}

export const IdentityForm: FC<IProps> = ({ formik, persona }) => {
  const [openAvatarListModal, setOpenAvatarListModal] = useState(false);
  const { activeAvatar } = avatarStore((state) => ({
    activeAvatar: state.activeAvatar
  }));
  const [openVoiceModal, setOpenVoiceModal] = useState(false);
  const { voices, selectVoiceModal: SelectVoiceModal } = useVoiceStore(
    (state) => ({
      voices: state.voices,
      selectVoiceModal: state.selectVoiceModal
    })
  );
  const currentVoice = voices?.find(
    (item) => item.voiceId === formik.values.voice
  );

  const imgUrl =
    activeAvatar?.inputImageUrl ??
    activeAvatar?.menuImageUrl ??
    activeAvatar?.rawImageUrl;
  return (
    <div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="name"
        >
          <div>Name</div>
        </label>

        <input
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.name && formik.touched.name
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="My Video Agent"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          name="name"
          id="name"
        />
        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>
      <div className="ag-flex ag-flex-col ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="description"
        >
          <div>Description</div>
        </label>
        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.description && formik.touched.description
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="description"
          id="description"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.description}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.description && formik.touched.description && (
              <FormError>{formik.errors.description}</FormError>
            )}
          </div>
        </div>
      </div>
      <Divider />
      {activeAvatar?.id === formik.values.avatarId && (
        <div className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold">
          <div className={'ag-mb-2 ag-mt-6'}>Avatar</div>
          <div
            className={
              'ag-rounded-lg ag-bg-neutral-50 ag-border ag-border-neutral-200 ag-flex ag-items-center ag-justify-between ag-p-2 ag-mb-6'
            }
          >
            <div className={'ag-flex ag-items-center'}>
              {activeAvatar && (
                <img
                  className={
                    'ag-w-[4.25rem] ag-h-[4.25rem] ag-rounded-lg ag-mr-2 ag-object-cover'
                  }
                  src={imgUrl}
                  alt={activeAvatar.name}
                />
              )}
              <div>
                <div className={'ag-text-md'}>{activeAvatar?.name}</div>
                <div className={'ag-tex-sm ag-text-neutral-600'}>
                  Interactive Avatar
                </div>
              </div>
            </div>
            <OutlinedButton
              onClick={() => setOpenAvatarListModal(true)}
              className={'ag-flex ag-gap-2 ag-px-3 ag-py-2'}
            >
              <SlRefresh size={20} />
              <div className={'ag-text-sm'}>Change</div>
            </OutlinedButton>
          </div>
          <Divider />
        </div>
      )}

      <TalkingPhotoListModal
        onChangeAvatarId={(value) => formik.setFieldValue('avatarId', value)}
        setOpen={setOpenAvatarListModal}
        open={openAvatarListModal}
      />

      <div className={'ag-mb-2 ag-mt-6 ag-font-semibold ag-text-sm'}>Voice</div>
      <VoiceSelectButton
        currentVoice={currentVoice || null}
        onClick={() => setOpenVoiceModal(true)}
      />
      <SelectVoiceModal
        currentAvatar={activeAvatar}
        currentVoice={
          voices?.find((item) => item.voiceId === formik.values.voice) ?? null
        }
        open={openVoiceModal}
        setOpen={setOpenVoiceModal}
        chooseVoice={(voice) => {
          formik.setFieldValue('voice', voice.voiceId);
        }}
      />
    </div>
  );
};
