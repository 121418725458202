import * as React from 'react';
import {
  isTrackReference,
  TrackReferenceOrPlaceholder
} from '@livekit/components-core';
import {
  TrackRefContext,
  useFeatureContext,
  useMaybeLayoutContext,
  useMaybeTrackRefContext
  // VideoTrack
} from '@livekit/components-react';
import { toast } from 'react-toastify';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { LoadingAnimation } from './LoadingAnimation';
import { videoCallStore } from 'features/livekit-call';
import { VideoTrack } from './VideoTrack';
import { useDynamicObjectFit } from 'shared/lib';

function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder;
  }>
) {
  const hasContext = !!useMaybeTrackRefContext();
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>
      {props.children}
    </TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

export interface ParticipantTileProps {
  trackRef?: TrackReferenceOrPlaceholder;
}

export function ParticipantTile({ trackRef }: ParticipantTileProps) {
  const layoutContext = useMaybeLayoutContext();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useDynamicObjectFit(videoRef);
  const autoManageSubscription = useFeatureContext()?.autoSubscription;
  const { setVideoStarted } = videoCallStore();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const isSubscribedRef = useRef(isSubscribed);
  isSubscribedRef.current = isSubscribed;

  const handleSubscribe = useCallback(
    (subscribed: boolean) => {
      if (subscribed) {
        toast.dismiss('livekit-connection');
        toast.clearWaitingQueue({
          containerId: 'livekit-connection'
        });
        if (!isSubscribedRef.current) setIsSubscribed(subscribed);
      }
    },
    [trackRef, layoutContext]
  );

  useEffect(
    () => () => {
      setVideoStarted(false);
    },
    []
  );

  const [animationLoaded, setAnimationLoaded] = useState(false);

  useEffect(() => {
    if (animationLoaded) {
      setVideoStarted(isSubscribed);
    }
  }, [animationLoaded, isSubscribed]);

  return (
    <div className="ag-relative ag-w-full ag-h-full ag-pb-[4.5rem] ag-flex ag-justify-center">
      {trackRef && (
        <TrackRefContextIfNeeded trackRef={trackRef}>
          {isTrackReference(trackRef) && (
            <VideoTrack
              ref={videoRef}
              trackRef={trackRef}
              onSubscriptionStatusChanged={handleSubscribe}
              manageSubscription={autoManageSubscription}
              className={`ag-w-full ag-h-full ag-object-top ${
                !animationLoaded
                  ? 'ag-fixed ag-pointer-events-none -ag-z-50 -ag-left-full'
                  : ''
              }`}
            />
          )}
        </TrackRefContextIfNeeded>
      )}
      {!animationLoaded && (
        <LoadingAnimation
          isLoaded={isSubscribed}
          estimateTime={10}
          onAnimationLoaded={() => setAnimationLoaded(true)}
        />
      )}
    </div>
  );
}
