import React, { FC, SetStateAction } from 'react';
import Modal from 'components/shared/Modal';
import Divider from 'components/Divider';

interface IProps {
  models: {
    value: string;
    name: string;
    description: string;
    icon: React.ReactNode;
  }[];
  onChoose: (value: string) => void;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const ChooseModelAiModal: FC<IProps> = ({
  models,
  setOpen,
  isOpen,
  onChoose
}) => {
  const handleClick = (value: string) => {
    onChoose(value);
    setOpen(false);
  };
  return (
    <Modal
      className={'ag-max-w-[556px] ag-max-h-[80vh] ag-overflow-y-auto'}
      open={isOpen}
      noPadding={true}
      setOpen={setOpen}
    >
      <div className={'ag-px-6 ag-pt-6'}>
        <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
          Model
        </h2>
        <div className={'ag-text-sm ag-text-neutral-600 ag-mb-5'}>
          Select the best model for your Video Agent.
        </div>
      </div>
      <Divider />
      <div className={'ag-p-6 ag-gap-5'}>
        {models.map((model) => (
          <div
            onClick={() => handleClick(model.value)}
            key={model.value}
            className={
              'ag-border ag-border-neutral-200 ag-rounded-xl ag-flex-1 ag-p-3 ag-cursor-pointer ag-mb-2 last:ag-mb-0'
            }
          >
            <div className={'ag-flex ag-items-center ag-justify-between'}>
              <div className={'ag-flex ag-items-center'}>
                {model.icon}
                <div className={'ag-ml-3'}>
                  <h3
                    className={'ag-text-neutral-900 ag-font-medium ag-text-sm'}
                  >
                    {model.name}
                  </h3>
                  <div className={'ag-text-neutral-600 ag-text-sm'}>
                    {model.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
