import 'react-toastify/dist/ReactToastify.min.css';
import '../index.css';

import { Navigate, Route, Routes } from 'react-router';
import React, { ComponentType, FC, ReactElement, useEffect } from 'react';

import {
  AVATARS_CALL_ROUTE,
  AVATARS_CREATE_PERSONA_ROUTE,
  AVATARS_EDIT_PERSONA_ROUTE,
  AVATARS_EMBED_ROUTE,
  CALL_PERSONA_ROUTE,
  CREATE_PERSONA_ROUTE,
  EDIT_PERSONA_ROUTE,
  EMBED_ROUTE,
  ENVIRONMENT,
  EVENT2_ROUTE,
  EVENT_ROUTE,
  featureFlags,
  IAvatar,
  IVoice,
  MAIN_ROUTE,
  TAVUS_EMBED_ROUTE
} from '../shared';

import { EmbedModal } from 'features/embed-persona';

import { UploadCustomPhoto } from 'widgets/upload-custom-photo/ui/UploadCustomPhoto';
import { ShareModal } from 'widgets/share-modal';

import { CreatePersonaPage } from 'pages/create-persona';
import { Dashboard } from 'pages/dashboard';
import { EditPersona } from 'pages/edit-persona';
import { Call } from 'pages/call';
import { CallEmbed } from 'pages/embed/ui/CallEmbed';
import { ToastContainer } from 'react-toastify';
import { HiOutlineX } from 'react-icons/hi';
import { features } from 'entities/credit';
import { userStore } from 'entities/user';
import { CallEvent, CallEvent2 } from 'pages/event';
import { AuthLoading } from './AuthLoading';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { TavusCall } from 'pages/tavus/call/ui';
import { TavusCallEmbed } from 'pages/embedTavus';
import { TavusEmbedModal } from 'features/tavus-embed-persona';
import { TavusCreatePersonaPage } from 'pages/tavus/create-persona';
import { ChooseCreateAgentModal } from 'widgets/choose-create-agent';
import { AvatarEditPersona } from 'pages/avatar-edit-persona';
import { TAvatarShareModal } from 'widgets/t-avatar-share-modal';

export type AppProps = {
  updateCallStore: (key: string, value: any) => void;
  toggleUpgradeModal: (isOpened: boolean) => void;
  toggleBuyCreditsModal: (isOpened: boolean) => void;
  toggleFeatureBlockModal: (open: boolean | typeof features[number]) => void;
  toggleUploadAvatar: () => void;
  toggleCreateAccModal: () => void;
  talkingPhotos: IAvatar[];
  customTalkingPhotos: IAvatar[];
  isChatAvailable: boolean;
  premiumVoicesAllowed: boolean;
  selectVoice: FC<{
    currentAvatar: IAvatar | null;
    currentVoice: IVoice | null;
    open: boolean;
    setOpen: (open: boolean) => void;
    chooseVoice: (value: IVoice) => void;
  }>;
  apiKey: string;
  agentKey: string;
  productName: string;
};

const App: FC<AppProps> = (props) => {
  const { me } = userStore();
  const { fetchReplicas } = replicaStore();

  useEffect(() => {
    me();
    if (featureFlags.tAvatars) {
      fetchReplicas();
    }
  }, []);

  return (
    <>
      <EmbedModal />
      <TavusEmbedModal />
      <ShareModal />
      <TAvatarShareModal />
      <UploadCustomPhoto />
      <ChooseCreateAgentModal />
      <Routes>
        <Route element={<AuthLoading {...props} />}>
          <Route path={MAIN_ROUTE} element={<Dashboard />} />
          <Route path={EDIT_PERSONA_ROUTE} element={<EditPersona />} />
          <Route path={CALL_PERSONA_ROUTE} element={<Call />} />
          <Route path={CREATE_PERSONA_ROUTE} element={<CreatePersonaPage />} />
          <Route path={EMBED_ROUTE} element={<CallEmbed />} />
          <Route path={EVENT_ROUTE} element={<CallEvent />} />
          <Route path={EVENT2_ROUTE} element={<CallEvent2 />} />

          {featureFlags.tAvatars && (
            <>
              <Route path={AVATARS_EMBED_ROUTE} element={<TavusCallEmbed />} />
              <Route path={AVATARS_CALL_ROUTE} element={<TavusCall />} />
              <Route
                path={AVATARS_EDIT_PERSONA_ROUTE}
                element={<AvatarEditPersona />}
              />
              <Route
                path={AVATARS_CREATE_PERSONA_ROUTE}
                element={<TavusCreatePersonaPage />}
              />
            </>
          )}

          {ENVIRONMENT !== 'beta' && ENVIRONMENT !== 'production' && (
            <>
              <Route path={TAVUS_EMBED_ROUTE} element={<TavusCallEmbed />} />
            </>
          )}

          <Route path="*" element={<Navigate to={MAIN_ROUTE} />} />
        </Route>
      </Routes>
      <ToastContainer
        closeButton={({ closeToast }) => (
          <button type="button" onClick={closeToast}>
            <HiOutlineX
              className="ag-size-5 ag-text-neutral-400 hover:ag-text-neutral-500"
              aria-hidden="true"
            />
          </button>
        )}
        hideProgressBar
      />
    </>
  );
};

export default App;
