import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { MAIN_ROUTE, showError } from 'shared/model';
import { Loader } from 'shared/ui';
import { useAuthStore } from 'stores/useAuthStore';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { AvatarEditPersonaLayout } from 'widgets/avatar-edit-persona-layout/ui/AvatarEditPersonaLayout';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';

export const AvatarEditPersona = () => {
  const { currentPersona, fetchOne, setCurrentPersona } = tavusPersonaStore(
    (state) => ({
      fetchOne: state.fetchOne,
      currentPersona: state.currentPersona,
      setCurrentPersona: state.setCurrentPersona
    })
  );
  const { setCurrentReplica, getReplicaById, replicas, fetchReplicas } =
    replicaStore((state) => ({
      setCurrentReplica: state.setCurrentReplica,
      getReplicaById: state.getReplicaById,
      replicas: state.replicas,
      fetchReplicas: state.fetchReplicas
    }));
  const [isLoading, setIsLoading] = useState(true);

  const { isAuth } = useAuthStore();

  const { id } = useParams();

  const nav = useNavigate();

  useEffect(() => {
    fetchReplicas();
  }, []);

  useEffect(() => {
    setCurrentReplica(getReplicaById(currentPersona?.default_replica_id!)!);
  }, [currentPersona, replicas]);

  useEffect(() => {
    if (!id) return nav(MAIN_ROUTE);

    if (isAuth) {
      setIsLoading(true);
      fetchOne(id)
        .then((success) => {
          if (!success) {
            !success && nav(MAIN_ROUTE);
          } else {
            setCurrentPersona(success);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      showError('You need to login to access this page.');
      nav(MAIN_ROUTE);
    }

    return () => {
      setCurrentPersona(null);
    };
  }, [id]);

  if (isLoading)
    return (
      <div className="ag-w-screen ag-h-screen ag-bg-white ag-flex ag-items-center ag-justify-center">
        <Loader size={96} />
      </div>
    );

  return (
    <>
      {currentPersona && <AvatarEditPersonaLayout persona={currentPersona} />}
    </>
  );
};
