/// <reference types="react-scripts" />
import ReactDOM from 'react-dom/client';
import './index.css';
import Mount from './mount';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <div className="px-4 xl:px-20 pt-6 lg:pt-10 xl:pt-16 pb-4 scrollbar-none">
    <Mount
      selectVoice={() => <></>}
      toggleUpgradeModal={() => {
        alert('upgradeModal');
      }}
      toggleFeatureBlockModal={() => {
        alert('featureModal');
      }}
      toggleBuyCreditsModal={() => {
        alert('buy credits modal');
      }}
      updateCallStore={() => {}}
      toggleUploadAvatar={() => {
        alert('uploadAvatar');
      }}
      talkingPhotos={[]}
      customTalkingPhotos={[]}
      toggleCreateAccModal={() => {
        alert('toggleCreateAccModal');
      }}
      isChatAvailable={true}
      premiumVoicesAllowed={false}
      productName={'premium'}
      apiKey={'e11066cf-2189-4fd9-b0ac-42401dbd0329'} // dev
      agentKey={'49d01fae-6180-4209-a307-74d745ba0de3'} // dev
    />
  </div>
);
