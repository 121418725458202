import React, { FC, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { cn, useDebounce } from 'shared/lib';

interface IProps {
  onChange: (value: string) => void;
  theme: 'light' | 'dark';
}

export const SearchInput: FC<IProps> = ({ onChange, theme }) => {
  const [nameInputValue, setNameInput] = useState('');

  const handleChange = useDebounce(onChange, 500);
  useEffect(() => {
    handleChange(nameInputValue);
  }, [nameInputValue]);

  return (
    <div className={'ag-relative'}>
      <IoIosSearch
        size={20}
        className={cn(
          'ag-absolute ag-left-3 ag-top-1/2 ag-transform -ag-translate-y-1/2',
          theme === 'dark' ? 'ag-text-neutral-100' : 'ag-text-neutral-500'
        )}
      />
      <input
        value={nameInputValue}
        onChange={(e) => setNameInput(e.target.value)}
        className={cn(
          'ag-border ag-rounded-md ag-pl-10 ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0 focus:ag-border-primary-800 ag-max-w-80',
          theme === 'dark'
            ? 'ag-bg-neutral-800 ag-border-neutral-600 ag-text-white placeholder:ag-text-gray-400 ag-py-3 ag-pr-3 ag-text-sm'
            : 'ag-bg-white ag-border-neutral-300 placeholder:ag-text-neutral-500 ag-text-neutral-900 ag-py-2 ag-pr-3.5'
        )}
        placeholder="Search an image"
      />
    </div>
  );
};
