import { IPersona, personaStore } from 'entities/persona';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { MAIN_ROUTE, showError } from 'shared/model';
import { EditPersonaLayout } from 'widgets/edit-persona-layout';
import { Loader } from 'shared/ui';
import { useAuthStore } from 'stores/useAuthStore';

export const EditPersona = () => {
  const { detailedPersona, getDetailedPersona } = personaStore();
  const [isLoading, setIsLoading] = useState(true);

  const { isAuth } = useAuthStore();

  const { id } = useParams();

  const nav = useNavigate();

  useEffect(() => {
    if (!id) return nav(MAIN_ROUTE);

    if (isAuth) {
      setIsLoading(true);
      getDetailedPersona(id)
        .then((success) => !success && nav(MAIN_ROUTE))
        .finally(() => setIsLoading(false));
    } else {
      showError('You need to login to access this page.');
      nav(MAIN_ROUTE);
    }

    return () => {
      getDetailedPersona(null);
    };
  }, [id]);

  if (isLoading)
    return (
      <div className="ag-w-screen ag-h-screen ag-bg-white ag-flex ag-items-center ag-justify-center">
        <Loader size={96} />
      </div>
    );

  return (
    <>{detailedPersona && <EditPersonaLayout persona={detailedPersona} />}</>
  );
};
