import { Popover, Transition } from '@headlessui/react';

export const KnowledgeTooltip = () => {
  return (
    <Popover
      as="div"
      className="ag-text-primary-600 ag-font-semibold ag-mb-4 ag-text-sm ag-relative ag-z-10"
    >
      <Popover.Button>How it works?</Popover.Button>
      <Transition
        enter="ag-transition ag-duration-150 ag-ease-out"
        enterFrom="ag-transform ag-scale-95 ag-opacity-0"
        enterTo="ag-transform ag-scale-100 ag-opacity-100"
        leave="ag-transition ag-duration-150 ag-ease-out"
        leaveFrom="ag-transform ag-scale-100 ag-opacity-100"
        leaveTo="ag-transform ag-scale-95 ag-opacity-0"
      >
        <Popover.Panel
          as="div"
          className="ag-absolute ag-block ag-top-6 -ag-left-0 ag-bg-white ag-py-2 ag-px-3 ag-rounded ag-text-neutral-900 ag-text-sm ag-w-80 md:ag-w-max"
        >
          <ul className="ag-list-disc ag-pl-3">
            <li>Ungrounded: it won’t use your uploaded source as knowledge</li>
            <li>
              Hybrid: it will use your uploaded source and general knowledge
            </li>
          </ul>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
