import { FC, useState } from 'react';
import { clsx } from 'clsx';
import { cn } from 'shared/lib';
import { ColorsTab } from 'widgets/choose-background/ui/ColorsTab';
import { TTheme } from 'widgets/choose-background/ui/types';
import { ImagesTab } from 'widgets/choose-background/ui/ImagesTab';
import VideosTab from 'widgets/choose-background/ui/VideosTab';

const tabs = ['Color', 'Images', 'Videos'];

interface IProps {
  selectedBackground: string;
  onChange: (background: string) => void;
  customImageBackgrounds: string[];
  customVideoBackgrounds: string[];
  setCustomImageBackgrounds: (backgrounds: string[]) => void;
  setCustomVideoBackgrounds: (backgrounds: string[]) => void;
  theme?: TTheme;
}

export const ChooseBackground: FC<IProps> = ({
  selectedBackground,
  onChange,
  customVideoBackgrounds,
  customImageBackgrounds,
  setCustomImageBackgrounds,
  setCustomVideoBackgrounds,
  theme = 'dark'
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [customColor, setCustomColor] = useState<string>('#F2F2F2');

  return (
    <div className="ag-min-h-96 ag-pt-2 ag-mb-16">
      <div
        className={cn(
          'ag-flex ag-border-b ag-mb-6',
          theme === 'dark' ? 'ag-border-neutral-800' : 'ag-border-neutral-200'
        )}
      >
        {tabs.map((tab, index) => (
          <div
            key={tab}
            className={clsx(
              'ag-flex-1 ag-font-semibold ag-cursor-pointer ag-text-center ag-py-3',
              currentTab === index
                ? theme === 'dark'
                  ? 'ag-text-neutral-100 ag-border-b-2 ag-border-neutral-100'
                  : 'ag-text-primary-700 ag-border-b-2 ag-border-primary-700'
                : 'ag-text-neutral-500'
            )}
            onClick={() => setCurrentTab(index)}
          >
            {tab}
          </div>
        ))}
      </div>
      {currentTab === 0 && (
        <ColorsTab
          selectedBackground={selectedBackground}
          theme={theme}
          customColor={customColor}
          onChange={onChange}
          setCustomColor={setCustomColor}
        />
      )}
      {currentTab === 1 && (
        <ImagesTab
          theme={theme}
          onChange={onChange}
          customImageBackgrounds={customImageBackgrounds}
          setCustomImageBackgrounds={setCustomImageBackgrounds}
          selectedBackground={selectedBackground}
        />
      )}
      {currentTab === 2 && (
        <VideosTab
          theme={theme}
          onChange={onChange}
          setCustomVideoBackgrounds={setCustomVideoBackgrounds}
          customVideoBackgrounds={customVideoBackgrounds}
          selectedBackground={selectedBackground}
        />
      )}
    </div>
  );
};
