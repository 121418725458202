import Modal from '../../../components/shared/Modal';
import { BiX } from 'react-icons/bi';
import Divider from '../../../components/Divider';
import { FilledButton, GoToEmbedRoute } from 'shared/ui';
import Input from '../../../components/shared/Input';
import { ENVIRONMENT, showSuccessMessage } from 'shared/model';
import { CreditsWarning } from 'features/embed-persona';
import { LuCopy } from 'react-icons/lu';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { useTavusGenerateEmbedCode } from 'features/tavus-embed-persona';

export const TAvatarShareModal = () => {
  const { setPersonaShareModal, personaShareModal } = tavusPersonaStore();
  const { widgetCode } = useTavusGenerateEmbedCode(personaShareModal?.id!);
  if (!personaShareModal) return null;
  const showGoToEmbedRoute =
    ENVIRONMENT === 'local' ||
    ENVIRONMENT === 'development' ||
    ENVIRONMENT === 'staging';
  return (
    <Modal
      setOpen={() => setPersonaShareModal(null)}
      open={!!personaShareModal}
      closeButton={
        <BiX
          onClick={() => setPersonaShareModal(null)}
          className="ag-cursor-pointer ag-w-11 ag-h-11 ag-p-2.5 ag-absolute ag-right-2.5 ag-top-2.5"
        />
      }
      noPadding
      className="!ag-w-[464px] max-sm:ag-mt-auto max-sm:ag-mb-2.5"
    >
      <div className="ag-text-left">
        <div className="ag-p-6 ag-pb-5">
          <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
            Share Video Agent
          </h2>
        </div>
        <Divider className="ag-border-neutral-200" />
        <div className="ag-p-6 ag-pb-0">
          <CreditsWarning />
        </div>
        <div className="ag-p-6 ag-space-y-4">
          <Divider className="ag-border-neutral-200" />
          <div className="ag-flex ag-items-center ag-justify-between ag-mt-6 ag-gap-2">
            <Input
              className="ag-w-full ag-py-2.5 ag-px-3.5 ag-text-neutral-900 ag-text-base ag-cursor-copy"
              onMouseDown={() =>
                navigator.clipboard
                  .writeText(widgetCode[4])
                  .then(() => showSuccessMessage('Share link copied'))
              }
              readOnly
              value={widgetCode[4]}
            />
            <FilledButton
              className="ag-py-2.5 ag-ml-1 ag-px-[1.125rem] ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-gap-3 ag-text-base"
              onClick={() =>
                navigator.clipboard
                  .writeText(widgetCode[4])
                  .then(() => showSuccessMessage('Share link copied'))
              }
            >
              <LuCopy className="ag-text-xl" />
              <div>Copy</div>
            </FilledButton>
          </div>
          {showGoToEmbedRoute && (
            <GoToEmbedRoute
              link={widgetCode[4]}
              onCloseModal={() => setPersonaShareModal(null)}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
