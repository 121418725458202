import React, { FC } from 'react';
import { cn } from 'shared/lib';
import { RxCrossCircled } from 'react-icons/rx';
import ColorSelect from 'shared/ui/ColorSelect/ColorSelect';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { TTheme } from 'widgets/choose-background/ui/types';
import { colorList, gradientList } from 'widgets/choose-background/model';

interface IProps {
  selectedBackground: string;
  theme: TTheme;
  customColor: string;
  onChange: (value: string) => void;
  setCustomColor: (color: string) => void;
}

export const ColorsTab: FC<IProps> = ({
  selectedBackground,
  theme = 'dark',
  customColor,
  onChange,
  setCustomColor
}) => {
  return (
    <>
      <div className="ag-text-sm ag-font-semibold ag-mb-4">Solid colours</div>
      <div className="ag-flex ag-flex-wrap ag-gap-2 ag-mb-6">
        <div
          className={cn(
            'ag-w-16 ag-h-16 ag-rounded-lg ag-border ag-cursor-pointer ag-flex ag-justify-center ag-items-center',
            selectedBackground === ''
              ? 'ag-border-[3px] ag-border-primary-600'
              : theme === 'dark'
              ? 'ag-border-neutral-700'
              : 'ag-border-neutral-200'
          )}
          onClick={() => onChange('')}
        >
          <RxCrossCircled size={32} />
        </div>
        <ColorSelect
          className={cn(
            'ag-w-16 ag-h-16 ag-rounded-lg ag-border',
            selectedBackground === customColor
              ? 'ag-border-[3px] ag-border-primary-600'
              : theme === 'dark'
              ? 'ag-border-neutral-700'
              : 'ag-border-neutral-200'
          )}
          onChange={(color) => {
            onChange(color);
            setCustomColor(color);
          }}
          pickerPosition="right"
          disableTransparent
          color={customColor}
        >
          <BsFillPlusCircleFill color="black" size={32} />
        </ColorSelect>
        {colorList.map((color) => (
          <div
            className={cn(
              'ag-w-16 ag-h-16 ag-rounded-lg ag-border ag-cursor-pointer',
              selectedBackground === color
                ? 'ag-border-[3px] ag-border-primary-600'
                : theme === 'dark'
                ? 'ag-border-neutral-700'
                : 'ag-border-neutral-200'
            )}
            onClick={() => onChange(color)}
            style={{ background: color }}
            key={color}
          />
        ))}
      </div>
      <div className="ag-text-sm ag-font-semibold ag-mb-4">Gradients</div>
      <div className="ag-flex ag-flex-wrap ag-gap-2">
        {gradientList.map((color) => (
          <div
            className={cn(
              'ag-w-16 ag-h-16 ag-rounded-lg ag-border ag-cursor-pointer',
              selectedBackground === color
                ? 'ag-border-[3px] ag-border-primary-600'
                : theme === 'dark'
                ? 'ag-border-neutral-700'
                : 'ag-border-neutral-200'
            )}
            onClick={() => onChange(color)}
            style={{ background: color }}
            key={color}
          />
        ))}
      </div>
    </>
  );
};
