import { dailyCallStore } from 'features/daily-call/model';
import { useEffect, useRef, useState } from 'react';
import { cn } from 'shared/lib';
import { TalkingMode } from './TalkingMode';
import { UserVideo, useWebcam } from 'features/recognize-emotions';
import { Captions } from 'widgets/captions';

type Props = {
  onCallEnd: () => void;
};

export const TavusControlBar = ({ onCallEnd }: Props) => {
  const {
    callObject,
    isRecordingMic,
    startMic,
    stopMic,
    messages,
    withCaptions
  } = dailyCallStore((state) => ({
    callObject: state.callObject,
    isRecordingMic: state.isRecordingMic,
    startMic: state.startMic,
    stopMic: state.stopMic,
    messages: state.messages,
    withCaptions: state.withCaptions
  }));

  const { stopWebcam, webcamLoading, isWebcam, startWebcam } = useWebcam();

  const webcamRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (callObject) {
      if (isRecordingMic) {
        callObject.setLocalAudio(true);
      } else {
        callObject.setLocalAudio(false);
      }
    }
  }, [isRecordingMic, callObject]);

  useEffect(() => {
    return () => {
      stopWebcam();
    };
  }, []);

  return (
    <div className="ag-text-white ag-absolute ag-bottom-0 ag-left-0 ag-right-0 ag-h-full ag-flex ag-flex-col ag-justify-between">
      <div className="ag-flex ag-flex-col ag-items-center ag-justify-end ag-h-full ag-relative pb-4">
        <div className="ag-pb-0.5 ag-flex ag-flex-col sm:ag-flex-row ag-items-start sm:ag-items-end ag-justify-between ag-gap-4 ag-w-full ag-px-4 sm:ag-px-8 ag-mb-4">
          <UserVideo ref={webcamRef} />

          <Captions
            withCaptions={withCaptions}
            messages={messages}
            isChatScreen
          />
        </div>
      </div>
      <div className="ag-border-t ag-bg-neutral-900 ag-border-neutral-600 min-h-1/5 ag-w-full">
        <div
          className={cn(
            'ag-flex ag-gap-4 ag-py-4 ag-px-6 ag-items-center ag-justify-center'
          )}
        >
          <button
            onClick={() => {
              if (isWebcam) return stopWebcam();

              if (webcamRef.current) startWebcam(webcamRef.current);
            }}
            disabled={webcamLoading}
            className="disabled:ag-opacity-80"
          >
            {isWebcam ? (
              <svg
                className="ag-size-10"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19.7224" cy="20" r="13.6111" fill="#525252" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30.6375 26.2568C30.8567 26.3695 31.1215 26.3565 31.3293 26.225C31.5386 26.0936 31.6667 25.861 31.6667 25.6111V15.5C31.6667 15.2501 31.5386 15.0176 31.3279 14.8861C31.1172 14.7532 30.8553 14.7431 30.636 14.8543L25.9723 17.2203V15.5C25.9723 14.3055 25.0142 13.3333 23.8369 13.3333H11.0244C9.84703 13.3333 8.88894 14.3055 8.88894 15.5V25.6111C8.88894 26.8057 9.84703 27.7778 11.0244 27.7778H23.8369C25.0142 27.7778 25.9723 26.8057 25.9723 25.6111V23.8908L30.6375 26.2568Z"
                  fill="#E5E5E5"
                />
              </svg>
            ) : (
              <svg
                className="ag-size-10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                fill="none"
              >
                <circle cx="19.9996" cy="20.0001" r="17.2222" fill="#DC2626" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM31.1972 14.6549L26.5277 16.9896V15.5902L32.0143 10.1036C32.2919 9.82601 32.2919 9.37472 32.0143 9.09712C31.7367 8.81952 31.2854 8.81952 31.0078 9.09712L9.65366 30.4513C9.37606 30.7289 9.37606 31.1802 9.65366 31.4578C9.93127 31.7354 10.3826 31.7354 10.6602 31.4578L14.7217 27.3962H24.3923C25.5739 27.3962 26.5277 26.4424 26.5277 25.2608V23.5667L31.1972 25.9014C31.4605 26.0139 31.704 25.984 31.8805 25.873C32.094 25.7306 32.2222 25.5028 32.2222 25.2608V15.2955C32.2222 15.0535 32.094 14.8257 31.8805 14.6834C31.6812 14.5552 31.4107 14.541 31.1972 14.6549ZM10.8395 27.254L24.8763 13.2172C24.7197 13.1745 24.5631 13.1603 24.3923 13.1603H11.5798C10.3982 13.1603 9.44438 14.1141 9.44438 15.2957V25.261C9.44438 26.1863 10.0281 26.9693 10.8395 27.254Z"
                  fill="#E5E5E5"
                />
              </svg>
            )}
          </button>
          <TalkingMode
            startMicrophone={startMic}
            stopMicrophone={stopMic}
            isRecordingMic={isRecordingMic}
          />
          <button onClick={onCallEnd} className="disabled:ag-opacity-80">
            <svg
              className="ag-size-10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 56 56"
              fill="none"
            >
              <g clipPath="url(#clip0_1358_2025)">
                <path
                  d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                  fill="#DC2626"
                />
                <path
                  d="M25.301 30.6687C29.1466 34.5143 32.9157 36.0744 34.2732 34.7169L36.1374 32.8531C36.7541 32.2365 37.5905 31.8901 38.4625 31.8901C39.3346 31.8901 40.171 32.2365 40.7876 32.8531L44.9728 37.0382C45.5894 37.6549 45.9358 38.4913 45.9358 39.3633C45.9358 40.2354 45.5894 41.0718 44.9728 41.6884L41.7121 44.9491C38.6312 48.03 29.5189 43.9175 20.7856 35.1845C16.4211 30.8179 13.5585 26.7049 11.7083 22.8374C9.85812 18.9699 9.48319 15.7976 11.0227 14.258L14.2834 10.9974C14.9001 10.3807 15.7365 10.0343 16.6085 10.0343C17.4806 10.0343 18.317 10.3807 18.9336 10.9974L23.1187 15.1812C23.7354 15.7978 24.0818 16.6342 24.0818 17.5063C24.0818 18.3783 23.7354 19.2147 23.1187 19.8314L21.2532 21.6999C19.8957 23.0562 21.4554 26.8248 25.301 30.6687Z"
                  fill="#EEEFEE"
                />
              </g>
              <defs>
                <clipPath id="clip0_1358_2025">
                  <rect width="56" height="56" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
