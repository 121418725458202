import { videoCallStore } from 'features/livekit-call';

type Props = {
  toggleCaptions: () => void;
  withCaptions: boolean;
};

export const ToggleCaptions = ({ toggleCaptions, withCaptions }: Props) => {
  return (
    <div onClick={toggleCaptions} className="ag-cursor-pointer">
      {!withCaptions ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3784_3489)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7071 1.70711C20.0976 1.31658 20.0976 0.68342 19.7071 0.29289C19.3166 -0.09763 18.6834 -0.09763 18.2929 0.29289L0.29289 18.2929C-0.0976301 18.6834 -0.0976301 19.3166 0.29289 19.7071C0.68342 20.0976 1.31658 20.0976 1.70711 19.7071L4.41421 17H17C18.6569 17 20 15.6569 20 14V6C20 4.76026 19.248 3.69615 18.1753 3.23895L19.7071 1.70711ZM16.4142 5L15.23 6.18425C15.9057 6.42162 16.4796 6.87579 16.8499 7.4731C17.1409 7.9425 16.9963 8.5589 16.5269 8.8499C16.0575 9.1409 15.4411 8.9963 15.1501 8.5269C14.9659 8.2298 14.5984 8 14.1434 8C13.4456 8 13 8.5118 13 9V11C13 11.4882 13.4456 12 14.1434 12C14.5984 12 14.9659 11.7702 15.1501 11.4731C15.4411 11.0037 16.0575 10.8591 16.5269 11.1501C16.9963 11.4411 17.1409 12.0575 16.8499 12.5269C16.2929 13.4254 15.2751 14 14.1434 14C12.4737 14 11 12.7209 11 11V10.4142C9.46781 11.9465 7.94662 13.4676 6.41421 15H17C17.5523 15 18 14.5523 18 14V6C18 5.44772 17.5523 5 17 5H16.4142Z"
              fill="#F5F5F5"
            />
            <path
              d="M3 3H13.5858L11.5858 5H3C2.44772 5 2 5.44772 2 6V14C2 14.1695 2.04219 14.3292 2.11665 14.4691L0.68165 15.9041C0.25572 15.3862 0 14.7229 0 14V6C0 4.34315 1.34315 3 3 3Z"
              fill="#F5F5F5"
            />
            <path
              d="M7.15007 8.5269C7.27135 8.7225 7.44914 8.8617 7.64926 8.9365L8.9366 7.64915C8.914 7.58887 8.8851 7.52991 8.8499 7.4731C8.2929 6.57462 7.27506 6 6.14337 6C4.47367 6 3 7.27906 3 9V11C3 11.7301 3.26521 12.3806 3.69809 12.8877L5.12938 11.4564C5.04521 11.3134 5 11.1555 5 11V9C5 8.5118 5.44557 8 6.14337 8C6.59838 8 6.96587 8.2298 7.15007 8.5269Z"
              fill="#F5F5F5"
            />
          </g>
          <defs>
            <clipPath id="clip0_3784_3489">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.00781 9.00105C3.00781 7.28203 4.47984 6.00439 6.14768 6.00439C7.2781 6.00439 8.29481 6.57837 8.85119 7.47585C9.14186 7.94473 8.99742 8.56044 8.52855 8.85112C8.05967 9.14179 7.44393 8.99735 7.15325 8.52848C6.96926 8.23171 6.60218 8.00216 6.14768 8.00216C5.45065 8.00216 5.00558 8.51339 5.00558 9.00105V10.9988C5.00558 11.4865 5.45065 11.9977 6.14768 11.9977C6.60218 11.9977 6.96926 11.7682 7.15325 11.4714C7.44393 11.0025 8.05967 10.8581 8.52855 11.1487C8.99742 11.4394 9.14186 12.0551 8.85119 12.524C8.29481 13.4215 7.2781 13.9955 6.14768 13.9955C4.47984 13.9955 3.00781 12.7178 3.00781 10.9988V9.00105Z"
            fill="#F5F5F5"
          />
          <path
            d="M14.1387 6.00439C12.4708 6.00439 10.9988 7.28203 10.9988 9.00105V10.9988C10.9988 12.7178 12.4708 13.9955 14.1387 13.9955C15.2691 13.9955 16.2858 13.4215 16.8422 12.524C17.1328 12.0551 16.9884 11.4394 16.5195 11.1487C16.0506 10.8581 15.4349 11.0025 15.1442 11.4714C14.9603 11.7682 14.5932 11.9977 14.1387 11.9977C13.4417 11.9977 12.9965 11.4865 12.9965 10.9988V9.00105C12.9965 8.51339 13.4417 8.00216 14.1387 8.00216C14.5932 8.00216 14.9603 8.23171 15.1442 8.52848C15.4349 8.99735 16.0506 9.14179 16.5195 8.85112C16.9884 8.56044 17.1328 7.94473 16.8422 7.47585C16.2858 6.57837 15.2691 6.00439 14.1387 6.00439Z"
            fill="#F5F5F5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.0111084 6.00447C0.0111084 4.34946 1.35276 3.00781 3.00776 3.00781H16.9921C18.6472 3.00781 19.9888 4.34946 19.9888 6.00447V13.9955C19.9888 15.6506 18.6472 16.9922 16.9921 16.9922H3.00776C1.35276 16.9922 0.0111084 15.6506 0.0111084 13.9955V6.00447ZM3.00776 5.00558C2.4561 5.00558 2.00888 5.4528 2.00888 6.00447V13.9955C2.00888 14.5472 2.4561 14.9944 3.00776 14.9944H16.9921C17.5438 14.9944 17.991 14.5472 17.991 13.9955V6.00447C17.991 5.4528 17.5438 5.00558 16.9921 5.00558H3.00776Z"
            fill="#F5F5F5"
          />
        </svg>
      )}
    </div>
  );
};
