import { BsFilter } from 'react-icons/bs';
import { FC } from 'react';
import Dropdown from 'components/shared/Dropdown';
import { AvatarOptions, GenderOptions } from 'shared/model';
import { avatarStore } from 'entities/avatar';
import Checkbox from 'components/shared/Checkbox';
import Divider from 'components/Divider';
import { filterAvatarStore } from '../model';
import { cn } from 'shared/lib';

type Props = {
  classAvatarWrapper?: string;
};

export const AvatarFilter: FC<Props> = ({ classAvatarWrapper = '' }) => {
  const { genderFilters, setGenderFilters, resetGenders } = avatarStore();

  const { resetFilters, typeFilters, handleSetTypeFilter } =
    filterAvatarStore();

  return (
    <div
      className={cn('ag-z-50 ag-relative sm:ag-w-full ', classAvatarWrapper)}
    >
      <Dropdown
        label="Filters"
        icon={<BsFilter className="ag-size-5" />}
        className="ag-left-0 ag-min-w-[12rem] ag-w-full"
        buttonClassName="ag-w-full ag-py-2.5 ag-px-4 ag-flex"
      >
        <div className="ag-pt-5 ag-pb-4 ag-text-white">
          <div className="ag-pl-3.5 ag-pr-4 ag-flex ag-justify-between ag-w-full ag-items-baseline">
            <span className="ag-text-sm ag-font-semibold">Filters</span>
            <button
              className={`text-sm font-inter ag-font-semibold ${
                typeFilters.length || genderFilters.length
                  ? 'ag-text-secondary'
                  : 'ag-text-white ag-pointer-events-none'
              }`}
              onClick={() => {
                resetFilters();
                resetGenders();
              }}
              type="button"
            >
              Reset
            </button>
          </div>
          <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-mt-5 ag-pl-3.5 ag-pr-4">
            <Checkbox
              label="Talking Photo"
              onClick={(value) => handleSetTypeFilter(value as AvatarOptions)}
              choosenValues={typeFilters}
              value="talking photo"
            />
            {/* <Checkbox
              label="Avatar"
              onClick={(value) => handleSetTypeFilter(value as AvatarOptions)}
              choosenValues={typeFilters}
              value="avatar"
            /> */}
            <Checkbox
              label="Custom"
              onClick={(value) => handleSetTypeFilter(value as AvatarOptions)}
              choosenValues={typeFilters}
              value="custom"
            />
          </div>
          <Divider className="ag-w-full ag-my-2 ag-border-neutral-600" />
          <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-pl-3.5 ag-pr-4">
            <Checkbox
              label="Male"
              onClick={(value) => setGenderFilters(value as GenderOptions)}
              choosenValues={genderFilters}
              value="male"
            />
            <Checkbox
              label="Female"
              onClick={(value) => setGenderFilters(value as GenderOptions)}
              choosenValues={genderFilters}
              value="female"
            />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
