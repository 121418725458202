import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import { ITavusPersonaFormik } from 'entities/tavus/persona';

type Props = {
  formik: FormikProps<ITavusPersonaFormik>;
  classNameBg?: string;
};

export const KnowledgeForm: FC<Props> = ({
  formik,
  classNameBg = 'ag-bg-neutral-800'
}) => {
  return (
    <div className="ag-space-y-10">
      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="greeting">
          <div>Knowledge</div>
          <div className="ag-text-sm ag-text-neutral-400">
            Enter information the Video Agent needs to know.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.context && formik.touched.context
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:border-primary-800'
          )}
          name="context"
          id="context"
          rows={5}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.context}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.context && formik.touched.context && (
              <FormError>{formik.errors.context}</FormError>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
