import React, { useEffect, useState } from 'react';
import { videoCallStore } from 'features/livekit-call';
import { cn, ITMessageBase } from 'shared';

interface CaptionsProps<T> {
  isChatScreen?: boolean;
  messages: T[];
  withCaptions: boolean;
}

export const Captions = <T extends ITMessageBase>({
  isChatScreen = false,
  messages,
  withCaptions
}: CaptionsProps<T>) => {
  const [assistantMessage, setAssistantMessage] = useState<T>();

  const getTheLatestMessage = (messages: T[]) => {
    if (messages.length === 0) setAssistantMessage(undefined);
    const assistantMessages = messages.filter((m) => m.role === 'assistant');
    setAssistantMessage(assistantMessages[assistantMessages.length - 1]);
  };

  useEffect(() => {
    getTheLatestMessage(messages);
  }, [messages]);

  if (!withCaptions || !assistantMessage) return null;

  return (
    <div
      className={cn(
        'ag-rounded-lg ag-bg-black/60 ag-text-white ag-z-50 ag-overflow-hidden',
        isChatScreen
          ? 'ag-text-sm md:ag-text-base md:ag-max-w-md lg:ag-max-w-xl ag-pl-3 ag-pr-2 ag-py-1 ag-static ag-mx-4 md:ag-mx-auto md:ag-absolute ag-bottom-4 md:ag-inset-x-0'
          : 'ag-text-sm md:ag-w-96 ag-mx-auto'
      )}
    >
      <div
        className={cn(
          'ag-max-h-16 ag-h-14 md:ag-h-max ag-w-full ag-scrollbar-thin ag-scrollbar-thumb-neutral-700 ag-scrollbar-track-transparent',
          isChatScreen
            ? 'md:ag-max-h-24 ag-overflow-y-auto'
            : 'md:ag-max-h-20 ag-overflow-y-scroll ag-p-2'
        )}
      >
        {assistantMessage?.content}
      </div>
    </div>
  );
};
