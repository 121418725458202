import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';

import { stockImagesApi } from '../api';
import { UnsplashImageDetailsShort } from './stockImagesModel';
import { getErrorMessage, useDebounce } from 'shared/lib';

const StockImagesContext = createContext<{
  error: string | null;
  loaded: boolean;
  images: UnsplashImageDetailsShort[];
  changeQuery: (query: string) => void;
  changePage: () => void;
}>({
  error: null,
  loaded: false,
  images: [],
  changeQuery: (query) => {},
  changePage: () => {}
});

export const useStockImages = () => {
  return useContext(StockImagesContext);
};

const initialPageSize = 18;

const StockImagesProvider = ({ children }: PropsWithChildren) => {
  const [images, setImages] = useState<UnsplashImageDetailsShort[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('video background');
  const [currentPage, setCurrentPage] = useState(1);

  const getImages = async (
    pageSize = initialPageSize,
    query = searchQuery,
    pageNum = currentPage
  ) => {
    setError(null);
    const queryStr = `?per_page=${pageSize}&page=${pageNum}&fit=clip&w=704&h=396&orientation=landscape&query=${query}`;

    const newimages = [...images];

    try {
      const data = await stockImagesApi.fetchPics(queryStr);
      if (typeof data === 'string') {
        setError(data);
      }
      const backgroundsData: UnsplashImageDetailsShort[] = data.map((r) => {
        return {
          id: r.id,
          photographer: {
            name: `${r.user.first_name} ${r.user.last_name}`,
            link: r.user.portfolio_url
          },
          regular: r.urls.regular,
          thumb: r.urls.small,
          alt: r.alt_description,
          unsplashLink: r.links.html
        };
      });

      if (newimages.length > 0) {
        const moreimages = newimages.concat(backgroundsData);
        setImages(moreimages);
      } else {
        setImages(backgroundsData);
      }
    } catch (error) {
      const message = getErrorMessage(error);

      setError(message);
    } finally {
      setLoaded(true);
    }
  };

  const changeQuery = (query: string) => {
    if (query !== searchQuery) {
      setError(null);
      setLoaded(false);
      setImages([]);
      setCurrentPage(1);
      setSearchQuery(query);
    }
  };

  const changePage = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const getDeboucedimages = useDebounce(
    () => getImages(initialPageSize, searchQuery, currentPage),
    500
  );

  useEffect(() => {
    setLoaded(false);
    getDeboucedimages();
  }, [searchQuery, currentPage]);

  return (
    <StockImagesContext.Provider
      value={{
        error,
        images,
        loaded,
        changeQuery,
        changePage
      }}
    >
      {children}
    </StockImagesContext.Provider>
  );
};

export default StockImagesProvider;
