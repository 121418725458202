import { MutableRefObject, useEffect, useRef } from 'react';
import { videoCallStore } from 'features/livekit-call';
import { ChatRole, cn } from 'shared';

export const ChatHistory = () => {
  const { messages, withCaptions, persona, addMessage } = videoCallStore();

  const myElementRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (ref: MutableRefObject<HTMLDivElement | null>) => {
    const container = ref.current;
    if (container) {
      const lastChild = container.lastElementChild;
      if (lastChild) {
        lastChild.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  useEffect(() => {
    if (messages.length === 0 && persona && !!persona.greeting)
      addMessage({
        role: ChatRole.ASSISTANT,
        content: persona.greeting,
        timestamp: new Date()
      });
    if (messages.length > 0) scrollToBottom(myElementRef);
  }, [messages]);

  if (!withCaptions) return null;

  return (
    <div
      ref={myElementRef}
      className="ag-space-y-4 ag-flex ag-flex-col ag-scrollbar-thin ag-scrollbar-thumb-neutral-700 ag-scrollbar-track-transparent ag-h-min ag-max-h-[220px] ag-overflow-y-scroll ag-text-sm sm:ag-text-base sm:ag-w-[318px] ag-static sm:ag-mx-auto sm:ag-absolute ag-bottom-20 sm:ag-inset-x-0 ag-mask-gradient ag-pt-8"
    >
      {messages &&
        messages.length > 0 &&
        messages.map((m, ind, mesArr) => (
          <div
            key={m.role + ind}
            className={cn(
              'ag-w-[286px] ag-grow-0 ag-text-white',
              m.role === 'user' ? 'ag-self-end ' : 'ag-self-start'
            )}
          >
            {mesArr[ind - 1]?.role !== m.role && (
              <div className="ag-mb-2">
                {m.role === 'user' ? 'You' : persona?.name}
              </div>
            )}
            <div
              className={cn(
                'ag-w-full ag-bg-black/60 ag-rounded-lg ag-py-2 ag-px-4 ag-text-white',
                m.role === 'user'
                  ? 'ag-self-end ag-rounded-tr-none'
                  : 'ag-self-start ag-rounded-tl-none'
              )}
            >
              {m.content}
            </div>
          </div>
        ))}
    </div>
  );
};
