import { ChatRole, ITMessageLivekit } from '../types';

const mdGovFile = `\`\`\`
eLearning Storyboard (Ver. 9 - 06 - 20 22)
Course Title: Making Partial Rating Decisions Microlesson
\`\`\`
**Purpose of the Training**
This is not the terminal learning objective (TLO). It is why the training was created? Was there a STAR review that identified trends in deficiencies or a
Congressional Action, etc.)?

\`\`\`
This is a refresher course for service connecting a condition on a presumptive basis.
\`\`\`
**Target Audience**
Who is this training meant for? Brand new employees with no VA/VBA experience? Is it an NTC course for ‘post-grads’?

\`\`\`
NTC course for ‘post-grads’
\`\`\`
**Prerequisites Knowledge and Skills**
Must the student possess prior knowledge and skills to complete this course. It could be a specialized software they must know for example.

\`\`\`
Learners must possess VIP training graduate knowledge of adjudicating claims.
\`\`\`
**Training Methodology (ILT or IWT, CBT, Blended)**
How will the training be presented to the student(s)? Instructor-led Training (ILT), Web-based Instructor led training (IWT), computer-based training
(ICW/CBT/ self-paced), or a blended lesson using a combination of these methodologies.

\`\`\`
Computer-based training (self-paced)
\`\`\`
**Assessment Strategy**
How will the students be tested or assessed on achievement of the objectives? Most lessons or courses have knowledge checks at the end of each topic of the
lesson and then there may be a scenario-based eCase to test the TLO.

\`\`\`
Learners will be assessed via knowledge checks within the course as well as a Level II assessment to be completed after the course is completed.
\`\`\`
**Media Selection Tool (MST)**
Was the MST used to aid in the selection of the media for each learning objective. If so, please attach the _Media Selection Tool Recommendation Tracker._ If the
MST was not used, please provide a brief explanation why it was not. Please refer to Mr. Scott Tanner (scott.tanner@va.gov) or Mr. Joshua Striker
(joshua.striker@va.gov) for any questions regarding these tools and their most current versions.

\`\`\`
MST was used and for the module and lesson objectives displayed IM-2 for the results.
\`\`\`

**Topic Outline**
This section breaks out the lesson by topic which is typically by Learning Objective. Multiple Learning Objectives maybe grouped into a topic if they are
closely related. An example would be If a lesson has three objectives, then the outline would be something like:

- Topic 1 (General Information on Partial Rating Decisions and Deferred Issues)
- Topic 2 (abbreviated LO #2)
- Topic 3 (abbreviated LO #3)

\`\`\`
Topic One: General Information on Partial Rating Decisions and Deferred Issues
\`\`\`
**Objectives**
_Use measurable verbs (e.g., Bloom’s Taxonomy); All content within a lesson must tie back to a stated objective. Can these objectives be linked back to a job task
analysis with supporting KSAs?_

\`\`\`
Terminal Learning Objective (TLO) [condition + standard + action]
Describes what the learner will be able to do after completion this lesson
MO Given available resources, determine entitlement to service connection on a presumptive basis with at least 80% accuracy
\`\`\`
\`\`\`
Lesson Objectives (LO) [condition + action]
States what the learner needs to do in support of the TLO.
LO 1 When Development to Obtain Additional Evidence May Be Needed.
\`\`\`
\`\`\`
LO 2 Identify the Definition: Partial Rating Decision.
LO 3 When to Make a Partial Rating Decision.
LO 4 Improper Use of a Partial Rating Decision for Service Connection (SC) for Cause of Death.
LO 5 When to Defer an Issue.
LO 6 Interpret the Procedure for Deferring Previously Denied Issues.
LO 7 What Action to Take When service connection (SC) for Cause of Death Is Deferred for Further Development.
\`\`\`
**Assessment Items**


_List the assessment items for each LO to include the methodology (simple test item, scenario-based e-Case, etc). Don’t just list the type, list the actual questions
and alternatives to include the correct response(s). If it’s an e-Case then that e-Case should be completed and included here._

\`\`\`
Objective Assessment method
MO Ex When development to obtain additional evidence such as a medical examination or other records may be needed if, it would
provide a more complete picture of a question at issue, and the evidence of record is questionable or conflicting?
MO 1 A. True
B. False
\`\`\`
\`\`\`
The correct answer is True, which covers service is V.ii.3.B.1.a. which covers the definition of When Development to Obtain Additional
Evidence May Be Needed.
\`\`\`
\`\`\`
Objective Assessment method
MO Ex A partial rating decision is a rating decision that provides a decision on some issues of a claim, but defers a decision on at least one
other issue, pending the outcome of additional development.
MO 2 A. Yes
B. No
\`\`\`
\`\`\`
The correct answer is yes, which covers service is V.ii.3.B.1.b. which covers Definition: Partial Rating Decision.
\`\`\`
\`\`\`
Objective Assessment method
MO Ex When making a partial rating decision when one or more issues require additional development before they can be decided, and
the record otherwise contains sufficient evidence to decide (favorably or not) at least one other issue, should you rate the claim?
MO 3 A. Yes
B. No
\`\`\`
\`\`\`
The correct answer is Yes: This is discussed in V.ii.3.B.1.c. which states: decide (favorably or not) at least one other issue, and/or, make
a proposed or final decision on a compensation entitlement issue, such as a reduction in service-connected disability evaluation,
severance of service connection (SC), or competency.
\`\`\`

\`\`\`
Objective Assessment method
MO Ex Do not use a partial rating decision to deny SC for cause of death if development is pending or in progress for another condition
possibly involved in the cause of death.
MO 4 A. True
B. False
\`\`\`
\`\`\`
The correct answer is True: Per V.ii.3.B.1.d. Improper Use of a Partial Rating Decision for SC for Cause of Death.
\`\`\`
\`\`\`
Objective Assessment method
MO Ex Defer an issue when the rating activity receives a claim that is underdeveloped or incomplete when proper disposition of that issue
depends on resolution of a development action.
MO 5 A. True
B. False
\`\`\`
\`\`\`
The correct answer is True: Per V.ii.3.B.1.e. When to Defer an Issue.
\`\`\`
**Slide Content**
_Describe the layout of your course and build out your content before you begin building in Captivate._

\`\`\`
Slide Title: Title Slide # 1 of 2 2
Audio Narration On-Screen Content
VA seal
Making Partial Rating Decisions
\`\`\`
\`\`\`
Compensation Service
_____________________
month/year
\`\`\`
\`\`\`
Interactivity & Programming Notes
Click anywhere or press Enter to begin.
\`\`\`
\`\`\`
Slide Title: Navigation Controls Slide # 2 of 22
\`\`\`

**Audio Narration On-Screen Content**
You can use the navigation buttons at the bottom to move forward and backward
throughout this module:

\`\`\`
You can also use the following keyboard shortcuts:
Next Slide: Right Arrow (→)
Previous Slide: Left Arrow (←)
Play: P
Pause: S
Closed Captioning: C
Rewind Ten Seconds: R
Fast Forward Ten Seconds: F
Submit: Y
Access Slide Notes/Hyperlinks: H
\`\`\`
On slides with audio select the ‘Play’ button (or P on the keyboard) to begin slide
audio.
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Welcome Slide # 3 of 22
Audio Narration On-Screen Content**
Hello, my name is Terry, I would like to welcome you to the
Making Partial Ratings Decisions microlesson! I have been tasked
to help the Training Consultants on the Curriculum Management
staff. I have been asked to help you navigate through this
microlesson. This microlesson is designed to provide you with
information on making partial rating decisions. Understanding
when to make a partial rating decision, can help benefit the
Veteran by awarding compensation the Veteran may be entitled
to before the entire claim is rated.

\`\`\`
Welcome to the microlesson on Making Partial Rating Decisions.
\`\`\`
\`\`\`
Given available resources, determine when to make a partial rating decision with
at least 80% accuracy.
\`\`\`
\`\`\`
The following learning objectives are covered:
\`\`\`
- When Development to Obtain Additional Evidence May Be Needed.
- Identify the Definition of a Partial Rating Decision.
- When to Make a Partial Rating Decision.
- Improper Use of a Partial Rating Decision for Service Connection (SC) for
    Cause of Death.
- When to Defer an Issue.


- Interpret the Procedure for Deferring Previously Denied Issues.
- What Action to Take When service connection (SC) for Cause of Death Is
    Deferred for Further Development.

**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Topic One Slide # 4 of 22
Audio Narration On-Screen Content**
Topic one which is the only topic in this microlesson will discuss
general information on partial rating decisions and deferred
Issues. This topic will discuss the learning objectives that were
covered on the previous slide. The screen shows the manual
reference that covers making partial rating decisions. If you
would like to review any of the CFRs or manual references
discussed in this microlesson you can select the reference button
to review them.

\`\`\`
General Information on Partial Rating Decisions and Deferred Issues
\`\`\`
\`\`\`
M21-1 V.ii.3.B — Making Partial Rating Decisions
\`\`\`
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: When Development to Obtain Additional Evidence May Be Needed Slide # 5 of 22
Audio Narration On-Screen Content**


When development to obtain additional evidence is needed. The
decision makers must be able to support the determination that
development is needed and may not develop with the purpose of
obtaining evidence to justify a denial of the claim. Please keep in
mind the considerations shown on the screen.

\`\`\`
Development to obtain additional evidence such as a medical examination or
other records may be needed if
\`\`\`
- it would provide a more complete picture of a question at issue, or
- the evidence of record is questionable or conflicting.
Note: Decision makers must maintain objectivity when assigning weight to a
claimant’s evidence and may not develop with the purpose of obtaining evidence
to justify a denial of the claim. Instead, decision makers must be able to support
the determination that development is needed.
References: For more information on
- ordering further development in cases where uncorroborated lay
evidence is presented, see Douglas v. Shinseki, 23 Vet.App. 19 (2009)
- a Veteran’s submission of evidence and refusal to attend a Department of
Veterans Affairs examination, see Kowalski v. Nicholson, 19 Vet.App. 171
(2005), and
- developing with the purpose of denying and explaining the need for
development, see Mariano v. Principi, 17 Vet.App. 305 (2003).
For more information, please review M21- V.ii.3.B.1.a.

**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Definition: Partial Rating Decision Slide # 6 of 22
Audio Narration On-Screen Content**
A partial rating decision can be performed if you can decide
(favorably or not) at least one other issue and defers another
issue for development or examination or examination
clarification.

\`\`\`
A partial rating decision is a rating decision that provides a decision on some
issues of a claim, but defers a decision on at least one other issue, pending the
outcome of additional development.
\`\`\`
\`\`\`
For more information, please review M21- V.ii.3.B.1.b.
\`\`\`

**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: When to Make a Partial Rating Decision Slide # 7 of 22
Audio Narration On-Screen Content**
The screen shows when you would complete a partial rating
decision. Please keep in mind the considerations shown on the
screen when completing partial rating decisions.

\`\`\`
Make a partial rating decision when one or more issues require additional
development before they can be decided, but the record otherwise contains
sufficient evidence to
\`\`\`
- decide (favorably or not) at least one other issue, and/or
- make a proposed or final decision on a compensation entitlement issue,
    such as a reduction in service-connected disability evaluation, severance
    of service connection (SC), or competency

\`\`\`
Important: The Veterans Benefits Administration’s mission is to serve Veterans
and their eligible dependents and survivors in the most timely and accurate
manner possible.
\`\`\`
\`\`\`
Exception: When issues cannot be resolved because of a Veteran’s return to
active duty, follow the procedures in M21-1, Part IV, Subpart ii, 2.A.4.c.
\`\`\`
\`\`\`
References: For more information on
\`\`\`
- evaluating competency, see M21-1, Part X, Subpart ii, 6.A
- proposed rating decisions, see M21-1, Part X, Subpart ii, 3.D
- reductions in awards, see M21-1, Part X, Subpart ii, 4.B, and
- severance of SC, see M21-1, Part X, Subpart ii, 5.B

\`\`\`
For more information, please review M21- V.ii.3.B.1.c.
\`\`\`

**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Improper Use of a Partial Rating Decision for SC for Cause of Death Slide # 8 of 22
Audio Narration On-Screen Content**
Do not use a partial rating decision to deny SC for cause of death if development is
pending or in progress for another condition possibly involved in the cause of
death.

\`\`\`
For more information, please review M21- V.ii.3.B.1.d.
\`\`\`
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Knowledge Check Slide # 9 of 22
Audio Narration On-Screen Content**
Hello! I think now is a great time for a knowledge check. I am
going to ask you a few questions about what we have learned so
far. I am going to ask two questions which are both yes and no
questions.

\`\`\`
This slide will have some form of picture that has a person thinking.
\`\`\`

**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Knowledge Check One Slide # 10 of 22
Audio Narration On-Screen Content
Yes/No**

\`\`\`
Is a partial rating decision a rating decision that provides a decision on some
issues of a claim, but defers a decision on at least one other issue, pending the
outcome of additional development?
\`\`\`
\`\`\`
Please select the correct response then select submit.
\`\`\`
- **Yes**
- No

\`\`\`
Correct - Great Job! Click anywhere or press ‘y’ to continue.
\`\`\`
\`\`\`
Sorry, that is not correct. Yes is the correct response. Please select the
correct answer and select submit before continuing.
\`\`\`
**Interactivity & Programming Notes**
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select Submit: Y.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Knowledge Check Two Slide # 1 1 of 22**


**Audio Narration On-Screen Content
Yes/No**

\`\`\`
Do you use a partial rating decision to deny SC for cause of death if development
is pending or in progress for another condition possibly involved in the cause of
death?
\`\`\`
\`\`\`
Please select the correct response then select submit.
\`\`\`
- Yes
- **No**

\`\`\`
Correct - Great Job! Click anywhere or press ‘y’ to continue.
\`\`\`
\`\`\`
Sorry, that is not correct. No is the correct response. Please select the correct
answer and select submit before continuing.
\`\`\`
**Interactivity & Programming Notes**
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select Submit: Y.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: When to Defer an Issue Slide # 12 of 22
Audio Narration On-Screen Content**
The rating activity sometimes gets claims that are
underdeveloped or incomplete. These claims will need to be
deferred to get a resolution of a development action. Please keep
in mind the considerations shown on the screen. If you would like
to review any of the CFRs or manual references discussed in this
microlesson you can select the reference button to review them.

\`\`\`
Defer an issue when the rating activity receives a claim that is underdeveloped or
incomplete when proper disposition of that issue depends on resolution of a
development action.
Reference: For more information on Veterans Benefits Management System
(VBMS) deferrals, see
\`\`\`
- M21-4, Chapter 6, 8.e for Veterans Service Center guidance
- M21-4, Chapter 7, 14 for pension management center guidance, and
- VBMS Deferral Job Aids.


For more information, please review M21- V.ii.3.B.1.e.
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Procedure for Deferring Previously Denied Issues Slide # 13 of 22
Audio Narration On-Screen Content**
There are certain steps to follow when a decision on a previously
denied issue, that now must be deferred shows correctly on the
codesheet. The screen shows how the deferred issue should look
on the codesheet, and we will discuss these steps over the next
few slides.

\`\`\`
When a decision on a previously denied issue must now be deferred, decision
makers should ensure the coded conclusion of the Codesheet lists the
\`\`\`
- previously denied issue as deferred, and
- date of the prior denial in the ORIGINAL DATE OF DENIAL field.

\`\`\`
Example:
\`\`\`
\`\`\`
DEFERRED ISSUES - VETERAN
\`\`\`
## 6006 REFRACTIVE ERROR

\`\`\`
Deferred
Original Date of Denial: 06/28/
\`\`\`
For more information, please review M21- V.ii.3.B.1.d.
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R


Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Procedure for Deferring Previously Denied Issues (Cont) Slide # 14 of 2 2
Audio Narration On-Screen Content**
The first step in the process to defer a previously
denied issue is shown on the screen. This slide shows
where you would locate the previously denied issue
card in VBMS-Rating.
1

\`\`\`
Ensure the
\`\`\`
- previously denied issue is reflected on the MASTER RECORD tab in VBMS-
    Rating, and
- appropriate date is listed in the ORIGINAL DATE OF DENIAL field.

\`\`\`
Hyph Diagnostic Code Bilateral Diagnosis
Code:
6066 Select Major refractor error
\`\`\`
\`\`\`
References : For more information on
\`\`\`
- required elements of the coded conclusion, see M21-1, Part V, Subpart iv,
    1.C.3.a, and
- backfilling the master record, see the _VBMS Rating User Guide_.

**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.


Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Procedure for Deferring Previously Denied Issues (Cont) Slide # 15 of 22
Audio Narration On-Screen Content**
This screen shows steps two through six and what
you need to update on the previously denied issue
card. It walks you through all the information needed
to update the card.

\`\`\`
References: For more information on
\`\`\`
completing rating decisions, see the VBMS Rating User Guide, and
the ORIGINAL DATE OF DENIAL field, see M21-1, Part V, Subpart iv, 1.C.9.b.
**Interactivity & Programming Notes**
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select Submit: Y.

**Slide Title: Action to Take When SC for Cause of Death Is Deferred for Further Development Slide # 16 of 22
Audio Narration On-Screen Content**
If the rating activity returns a case further development with
regard to the issue of service connection (SC) for cause of death.
The screen shows what must be done prior to the claim being
returned to the rating activity.

\`\`\`
If the rating activity returns a case for further development with regard to the
issue of SC for cause of death,
\`\`\`
- request the indicated evidence


- award pension benefits if entitlement is established and an award was not
    made prior to submission of the case to the rating activity, and
- establish a 30-day control for submission of the requested evidence.

Once the 30-day evidentiary submission period has elapsed, resubmit the case to
the rating activity for issuance of a formal decision on the issue of SC for cause of
death.
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Knowledge Check Slide # 17 of 22
Audio Narration On-Screen Content**
Once again, I think now is a great time for a knowledge check. I
am going to ask you a few questions about what we have learned
so far. I am going to ask two questions which are both true and
false questions.

\`\`\`
This slide will have some form of picture that has a person thinking.
\`\`\`
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Knowledge Check Three Slide # 1 8 of 22
Audio Narration On-Screen Content
True/False**


\`\`\`
Once the 30-day evidentiary submission period has elapsed, for further
development with regard to the issue of SC for cause of death. You should change
the end product (EP) to a 400ep and clear the claim?
\`\`\`
\`\`\`
Please select the correct response then select submit.
\`\`\`
- True
- **False**

\`\`\`
Correct - Great Job! Click anywhere or press ‘y’ to continue.
\`\`\`
\`\`\`
Sorry, that is not correct. False is the correct response. Please select the
correct answer and select submit before continuing.
\`\`\`
**Interactivity & Programming Notes**
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select Submit: Y.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Knowledge Check Four Slide # 1 9 of 22
Audio Narration On-Screen Content
True/False**

\`\`\`
When a decision on a previously denied issue must now be deferred, decision
makers should ensure the coded conclusion of the Codesheet lists the previously
denied issue as deferred, and date of the prior denial in the original date of denial
field.
\`\`\`
\`\`\`
Please select the correct response then select submit.
\`\`\`
- **True**
- False

\`\`\`
Correct - Great Job! Click anywhere or press ‘y’ to continue.
\`\`\`
\`\`\`
Sorry, that is not correct. True is the correct response. Please select the
correct answer and select submit before continuing.
\`\`\`

**Interactivity & Programming Notes**
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select Submit: Y.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Wrap-Up & What’s next? Slide# 20 of 22
Audio Narration On-Screen Content**
Congratulations, you've completed this module. You should now
be able to accomplish the objectives listed on the screen. Please
note that you are required to pass an assessment with a
minimum accuracy of 80% in order to receive credit for this
course. Please continue to view applicable references before
moving on to the assessment.

- When Development to Obtain Additional Evidence May Be Needed.
- Identify the Definition of a Partial Rating Decision.
- When to Make a Partial Rating Decision.
- Improper Use of a Partial Rating Decision for Service Connection (SC) for
    Cause of Death.
- When to Defer an Issue.
- Interpret the Procedure for Deferring Previously Denied Issues.
- What Action to Take When service connection (SC) for Cause of Death Is
    Deferred for Further Development.

\`\`\`
Important — You must complete the assessment with minimum passing grade of
80 % to receive credit. Please continue to view applicable references before
moving on to the assessment in Talent Management System (TMS).
\`\`\`
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the play button (P on the keyboard) to play audio.
Learner can select the pause button (S on the keyboard) to pause audio.
Learner can select the closed captioning button (C on the keyboard) to turn/off closed captioning.
Learner can select the Rewind Ten Seconds: R
Learner can select the Fast Forward Ten Seconds: F
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.


**Slide Title: Would you like to know more? Slide # 21 of 22
Audio Narration On-Screen Content**
Note - press the reference button or select H on your keyboard to view the references shown.

\`\`\`
M21-1, Part V, Subpart iv, Chapter 1, Section C - Coded Conclusion
M21-1, Part V, Subpart ii, Chapter 3, Section B - Making Partial Rating Decisions
M21-1, Part X, Subpart ii, Chapter 3, Section D - Completing Proposed Rating Decisions
M21-1, Part X, Subpart ii, Chapter 4, Section B - Authorization for Reductions in Disability
Evaluations Under 38 CFR 3.105(e)
M21-1, Part X, Subpart ii, Chapter 5, Section B - Severance of Service Connection (SC)
M21-1, Part X, Subpart ii, Chapter 6, Section A - Evaluating and Rating Competency
M21-4, Chapter 6, 8.e for Veterans Service Center guidance
\`\`\`
**Interactivity & Programming Notes**
Learner will select the right arrow (on-screen or via keyboard) to navigate to the next slide.
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.

**Slide Title: Would you like to know more? Slide # 22 of 22
Audio Narration On-Screen Content**

\`\`\`
Note - press the reference button or select H on your keyboard to view the references shown.
\`\`\`
\`\`\`
M21-4, Chapter 7, 14 for pension management center guidance
Douglas v. Shinseki, Apr 17, 2009, 23 Vet.App. 19
Kowalski v. Nicholson, Jun 8, 2005, 19 Vet.App. 171
Mariano v. Principi, Oct 22, 2003, 17 Vet.App. 305
VBMS Deferral Job Aids
\`\`\`

**Interactivity & Programming Notes**
Learner will select the left arrow (on-screen or via keyboard) to navigate to the previous slide.
Learner can select the reference button (H on the keyboard) to Access Slide Notes/Hyperlinks.
The learner can exit the module by clicking the seal or pressing Enter.
`;

export const getChatInitialMessages = (vaGov: boolean): ITMessageLivekit[] => {
  if (vaGov)
    return [
      {
        role: ChatRole.SYSTEM,
        content: `Your primary mission is to ask questions to a student and to correct them when they get an answer wrong. It's really important that you keep the questions coming and you keep conversation moving.
        You must ask questions about the training course in the MD content below.
        You must open the conversation by asking questions to test the users knowledge
        Opening statement..Thank you for completing part 1 of making partial rating decisions, let's test your knowledge
        
        MD content: ${mdGovFile}`,
        timestamp: new Date(),
        language: 'en-US'
      }
    ];

  return [
    {
      role: ChatRole.SYSTEM,
      content:
        "Create a conversational AI that can engage users in meaningful and informative discussions on a wide range of topics. The AI should be able to provide accurate and up-to-date information, answer questions, offer suggestions, and maintain a friendly and natural conversational tone. Prioritize adaptability to user input and demonstrate the ability to handle both casual and formal conversations with ease. Consider incorporating elements of creativity and humor to enhance the user experience. Strive for a balance between depth of knowledge and approachability in the AI's responses. Your name is Lisa.",
      timestamp: new Date(),
      language: 'en-US'
    }
  ];
};
