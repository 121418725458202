import { createRef, ReactNode, useState } from 'react';
import {
  HexAlphaColorPicker,
  HexColorInput,
  HexColorPicker
} from 'react-colorful';
import { useClickOutside, useDebounce } from 'shared/lib';
import clsx from 'clsx';

interface ColorSelectProps {
  color: string | undefined;
  onChange: (color: string) => void;
  disabled?: boolean;
  className?: string;
  pickerPosition?: 'left' | 'right' | 'center';
  disableTransparent?: boolean;
  children?: ReactNode;
}

const ColorSelect = ({
  color,
  onChange,
  disabled,
  className,
  pickerPosition = 'center',
  disableTransparent = false,
  children
}: ColorSelectProps) => {
  const [isShown, setIsShown] = useState(false);
  const ref = createRef<HTMLDivElement>();

  useClickOutside(ref, setIsShown);

  const debouncedOnChange = useDebounce(onChange, 200);

  return (
    <>
      <div
        style={{ backgroundColor: color }}
        className={`ag-block ag-text-sm ag-text-black ${
          disabled ? 'ag-cursor-default' : 'ag-cursor-pointer'
        } ${className}`}
      >
        <div
          onClick={() => setIsShown((prevState) => !prevState)}
          className={clsx(
            `ag-size-full ag-flex ag-justify-center ag-items-center`
          )}
        >
          {children}
        </div>
        {isShown && (
          <div ref={ref} className="ag-relative ag-z-40 ag-top-2">
            <div
              className={`ag-w-[13.5rem] ag-border ag-rounded-xl ag-mx-auto ag-p-2 ag-content-center ag-bg-white ag-block ag-shadow-md ${
                pickerPosition === 'left' && '-ag-ml-44'
              } ${pickerPosition === 'right' && 'ag-ml-0'} ${
                pickerPosition === 'center' && '-ag-ml-24'
              }`}
            >
              {disableTransparent ? (
                <HexColorPicker
                  className="content-center"
                  color={color}
                  onChange={debouncedOnChange}
                />
              ) : (
                <HexAlphaColorPicker
                  className="content-center"
                  color={color}
                  onChange={debouncedOnChange}
                />
              )}
              <HexColorInput
                className="ag-mt-2 ag-block ag-mx-auto ag-px-2 ag-bg-color-grey ag-uppercase ag-text-center ag-max-w-full"
                prefixed={true}
                color={color}
                onChange={debouncedOnChange}
                alpha={!disableTransparent}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ColorSelect;
