import React from 'react';
import { IAvatar } from '../../../shared/model/types';
import clsx from 'clsx';

type AvatarItemProps = {
  avatar: IAvatar;
  onChooseAvatar: (avatar: IAvatar) => void;
  isActive?: boolean;
};

export const TalkingPhotoListItem: React.FC<AvatarItemProps> = ({
  avatar,
  onChooseAvatar,
  isActive
}) => {
  const imgUrl =
    avatar.inputImageUrl ?? avatar.menuImageUrl ?? avatar.rawImageUrl;

  const handleChooseAvatar = () => {
    onChooseAvatar(avatar);
  };

  return (
    <div
      onClick={handleChooseAvatar}
      className={clsx(
        `ag-relative ag-group ag-h-fit`,
        isActive ? 'ag-cursor-default' : 'ag-cursor-pointer'
      )}
    >
      {imgUrl && (
        <img
          src={imgUrl}
          alt={avatar.name}
          className={`ag-w-24 ag-aspect-square ag-border ag-object-cover ag-rounded ${
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-100'
          } `}
        />
      )}
      <span className="ag-text-neutral-600 ag-text-sm ag-inline ag-bottom-2 ag-left-2">
        {avatar.name}
      </span>
    </div>
  );
};
