import { useNavigate } from 'react-router';
import { FilledButton } from './Buttons';

type Props = {
  link: string;
  onCloseModal: () => void;
};

export const GoToEmbedRoute = ({ link, onCloseModal }: Props) => {
  const nav = useNavigate();

  const handleGoToEmbedRoute = () => {
    nav(link.replace(window.location.origin, ''));
    onCloseModal();
  };

  return (
    <FilledButton
      onClick={handleGoToEmbedRoute}
      className="ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm hover:ag-bg-primary-700"
    >
      To Embed route
    </FilledButton>
  );
};
