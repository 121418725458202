import * as React from 'react';
import {
  isTrackReference,
  TrackReferenceOrPlaceholder
} from '@livekit/components-core';
import {
  TrackRefContext,
  useFeatureContext,
  useMaybeTrackRefContext
  // VideoTrack
} from '@livekit/components-react';
import { VideoTrack } from './VideoTrack';
import { useDynamicObjectFit } from 'shared/lib';

function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder;
  }>
) {
  const hasContext = !!useMaybeTrackRefContext();
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>
      {props.children}
    </TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

export interface EmbedParticipantTileProps {
  trackRef?: TrackReferenceOrPlaceholder;
  isSubscribed: boolean;
  handleSubscribe: (subscribed: boolean) => void;
  className: string;
}

export function EmbedParticipantTile({
  trackRef,
  handleSubscribe,
  className
}: EmbedParticipantTileProps) {
  const autoManageSubscription = useFeatureContext()?.autoSubscription;
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  useDynamicObjectFit(videoRef);
  return (
    <>
      {trackRef && (
        <TrackRefContextIfNeeded trackRef={trackRef}>
          {isTrackReference(trackRef) && (
            <VideoTrack
              ref={videoRef}
              trackRef={trackRef}
              onSubscriptionStatusChanged={handleSubscribe}
              manageSubscription={autoManageSubscription}
              className={className}
            />
          )}
        </TrackRefContextIfNeeded>
      )}
    </>
  );
}
