const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || '';
};

export const ENVIRONMENT = getEnvVar('REACT_APP_ENVIRONMENT');

export const DEFAULT_AVATAR = getEnvVar('REACT_APP_DEFAULT_AVATAR');
export const DEFAULT_VOICE = getEnvVar('REACT_APP_DEFAULT_VOICE');

export const FEATURE_FLAG_STOP_TALK = getEnvVar(
  'REACT_APP_FEATURE_FLAG_STOP_TALK'
);
export const FEATURE_FLAG_EMBED = getEnvVar('REACT_APP_FEATURE_FLAG_EMBED');
export const FEATURE_FLAG_SHARE = getEnvVar('REACT_APP_FEATURE_FLAG_SHARE');
export const FEATURE_FLAG_T_AVATARS = getEnvVar(
  'REACT_APP_FEATURE_FLAG_T_AVATARS'
);
export const TAVUS_BASEURL = getEnvVar('REACT_APP_TAVUS_BASEURL');
export const UNSPLASH_KEY = getEnvVar('REACT_APP_UNSPLASH_KEY');
export const PEXELS_KEY = getEnvVar('REACT_APP_PEXELS_APIKEY');
