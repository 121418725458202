import { FC, useRef } from 'react';
import { useUploadFile } from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/hooks/useUploadFile';
import { Loader } from 'shared/ui';
import { LuUploadCloud } from 'react-icons/lu';
import { getErrorMessage } from 'shared/lib';
import { showError } from 'shared/model';

interface Props {
  onUpload: (src: string) => void;
  inputId: string;
}

export const AddCustomImage: FC<Props> = ({ onUpload, inputId }) => {
  const uploadref = useRef<HTMLInputElement | null>(null);

  const { isLoading, error, uploadFile } = useUploadFile();

  const handleUpload = async (files: FileList) => {
    try {
      const data = await uploadFile(files[0]);
      if (!data) return;
      if (data.mediaLink) {
        onUpload(data.mediaLink);
      }
    } catch (error) {
      const message = getErrorMessage(
        error,
        'There was a problem when we tried to upload file. Please try again later.'
      );
      showError(message);
    }
  };

  return (
    <div>
      <label className="block cursor-pointer" htmlFor={inputId}>
        <div className="inline-flex items-center justify-center px-2.5 sm:ag-py-2.5 border  shadow-sm text-sm font-medium rounded-lg group relative bg-white w-40 ag-text-neutral-700">
          <LuUploadCloud className={'ag-mr-2'} />
          {isLoading ? <Loader size={20} /> : 'Upload image'}
        </div>
        <input
          ref={uploadref}
          accept="image/png, image/jpeg, image/jpg"
          className="hidden"
          type="file"
          id={inputId}
          name={inputId}
          onChange={(e) => e.target.files && handleUpload(e.target.files)}
        />
      </label>

      {error && error}
    </div>
  );
};
