import { FC, PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { MdOutlineTranslate, MdOutlineRemoveRedEye } from 'react-icons/md';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { HiCode } from 'react-icons/hi';
import { FiEdit2, FiPhoneCall, FiShare2 } from 'react-icons/fi';
import { LuTrash2 } from 'react-icons/lu';

import { videoCallStore } from 'features/livekit-call';

import SelectInput from 'components/shared/SelectInput';
import Dropdown from 'components/shared/Dropdown';

import { IPersonaList, personaStore, Visibility } from 'entities/persona';
import { DeletePersonaModal } from 'features/delete-persona';
import {
  CALL_PERSONA_ROUTE,
  EDIT_PERSONA_ROUTE,
  featureFlags,
  LOGIN_ROUTE,
  STT_LOCALES,
  STT_LOCALES_NAMES
} from 'shared/model';
import { FilledButton } from 'shared';
import { useAuthStore } from 'stores/useAuthStore';
import { creditStore } from 'entities/credit';

interface Props {
  persona: IPersonaList;
}

const PersonaWrapper: FC<PropsWithChildren<Props & { className?: string }>> = ({
  persona,
  className = '',
  children
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const nav = useNavigate();

  const { setPersonaShareModal, togglePersonaEmbedModal } = personaStore();

  const { isAuth, toggleCreateAccModal } = useAuthStore();

  const { toggleFeatureBlockModal, isChatAvailable, productName } =
    creditStore();

  const handleEditPersona = () => {
    if (isAuth) {
      if (isChatAvailable) {
        nav(EDIT_PERSONA_ROUTE.replace(':id', persona.id));
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const handleSharePersona = () => {
    if (isAuth) {
      if (isChatAvailable) {
        setPersonaShareModal(persona);
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  const handleEmbedPersona = () => {
    const isEmbed: boolean =
      !!productName &&
      (productName.toLowerCase() === 'plus' ||
        productName.toLowerCase() === 'premium');

    if (isAuth) {
      if (isChatAvailable) {
        togglePersonaEmbedModal(persona.id);
      } else {
        toggleFeatureBlockModal('embed');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  return (
    <>
      <div
        className={`ag-relative ag-group ag-h-72 lg:ag-h-72 3xl:ag-h-96 ag-p-3 ag-border ag-bg-white dark:ag-bg-neutral-800 dark:ag-border-neutral-800 ag-rounded-lg hover:ag-border-primary-600 ag-cursor-pointer hover:ag-cursor-auto ${className}`}
      >
        <Dropdown
          icon={
            <BiDotsHorizontalRounded className="ag-w-5 ag-h-5 !ag-text-neutral-800 !ag-border-neutral-200" />
          }
          buttonClassName="!ag-border-neutral-200 !ag-text-neutral-700 ag-bg-white ag-absolute ag-top-0 ag-right-0 ag-p-1.5 ag-rounded-md ag-flex ag-items-center ag-justify-center ag-group ag-cursor-pointer"
          optionClassName="!ag-bg-white !ag-text-neutral-700 hover:!ag-bg-neutral-200"
          options={[
            ...(persona.canEdit && persona.visibility !== Visibility.PUBLIC
              ? [
                  {
                    label: 'Edit',
                    onClick: handleEditPersona,
                    icon: FiEdit2
                  }
                ]
              : []),
            ...(featureFlags.share
              ? [
                  {
                    label: 'Share',
                    onClick: handleSharePersona,
                    icon: FiShare2
                  }
                ]
              : []),
            ...(featureFlags.embed
              ? [
                  {
                    label: 'Embed',
                    onClick: handleEmbedPersona,
                    icon: HiCode
                  }
                ]
              : []),
            ...(persona.visibility !== Visibility.PUBLIC
              ? [
                  {
                    label: 'Delete',
                    onClick: () =>
                      isAuth
                        ? setDeleteModalOpen(true)
                        : toggleCreateAccModal(),
                    icon: LuTrash2
                  }
                ]
              : [])
          ]}
          className="ag-w-36 !ag-bg-white !ag-border-neutral-200"
          wrapperClassName="lg:group-hover:ag-opacity-100 lg:ag-opacity-0 ag-absolute ag-top-0 ag-right-0 ag-z-10"
        />
        <div className="ag-pb-3 ag-h-44 3xl:ag-h-[270px] ag-flex ag-items-center ag-justify-center">
          <img
            className="ag-w-full ag-h-full ag-rounded-lg ag-object-cover ag-object-top"
            src={persona.avatarUrl}
            alt={`${persona.name} persona preview`}
          />
        </div>
        {children}
      </div>
      <DeletePersonaModal
        persona={persona}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
      />
    </>
  );
};

export const Persona: FC<Props> = ({ persona }) => {
  const { setLocale, sttLocale } = videoCallStore();
  const { setActivePersona } = personaStore();
  const { isAuth, toggleCreateAccModal } = useAuthStore();
  const { isChatAvailable, toggleFeatureBlockModal } = creditStore();
  const nav = useNavigate();

  const handlePersonaCall = () => {
    if (isAuth) {
      if (isChatAvailable) {
        setActivePersona(persona.id);
        nav(CALL_PERSONA_ROUTE.replace(':id', persona.id));
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  return (
    <PersonaWrapper persona={persona}>
      <div className="group-hover:ag-hidden">
        <h3 className="ag-text-sm ag-font-semibold ag-text-neutral-900 dark:ag-text-white ag-mb-1">
          {persona.name}
        </h3>
        <span className="ag-text-xs ag-line-clamp-3 ag-text-600 dark:ag-text-neutral-400">
          {persona.description}
        </span>
      </div>
      <div className="ag-hidden group-hover:ag-block">
        <SelectInput
          name="select-language"
          options={STT_LOCALES_NAMES}
          value={STT_LOCALES_NAMES[STT_LOCALES.indexOf(sttLocale)]}
          onChange={(value) =>
            setLocale(STT_LOCALES[STT_LOCALES_NAMES.indexOf(value)])
          }
          optionsWrapperClassName="!ag-bg-white !ag-border-neutral-200"
          className="ag-h-7 !ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700"
          wrapperClassName="!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200"
          icon={<MdOutlineTranslate className="ag-size-6 ag-p-1" />}
          optionsClassName="ag-text-neutral-700 ag-border-neutral-200 ag-text-sm ag-py-1 hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80"
        />

        <FilledButton
          onClick={handlePersonaCall}
          className="ag-py-2 ag-w-full ag-mt-3.5"
        >
          <FiPhoneCall className="ag-w-5 ag-h-5 ag-mr-2" />
          Call me
        </FilledButton>
      </div>
    </PersonaWrapper>
  );
};
