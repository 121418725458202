import { Dispatch, FC, SetStateAction } from 'react';
import Modal from '../../../components/shared/Modal';
import { BiX } from 'react-icons/bi';
import { FilledButton, Loader, OutlinedButton } from 'shared/ui';
import { ITavusPersona, tavusPersonaStore } from 'entities/tavus/persona';

export const TavusDeletePersonaModal: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  persona: ITavusPersona;
}> = ({ ...props }) => {
  const { deletePersona, isPersonaDeleting } = tavusPersonaStore();

  const toggleModal = () => {
    props.setOpen((prev) => !prev);
  };

  return (
    <Modal
      {...props}
      closeButton={
        <BiX
          onClick={() => props.setOpen(false)}
          className="ag-cursor-pointer ag-w-11 ag-h-11 ag-p-2.5 ag-absolute ag-right-2.5 ag-top-2.5"
        />
      }
      className="!ag-w-96 ag-p-6 max-sm:ag-mt-auto max-sm:ag-mb-16"
      noPadding
    >
      <h3 className="ag-mb-1 ag-text-lg ag-font-semibold ag-text-neutral-900">
        Delete Video Agent
      </h3>
      <span className="ag-text-sm ag-text-neutral-600">
        Are you sure you want to delete this character? This action cannot be
        undone.
      </span>
      <div className="ag-mt-8 ag-flex ag-flex-col-reverse sm:ag-flex-row ag-items-center ag-justify-between ag-gap-3">
        <OutlinedButton
          onClick={toggleModal}
          className="ag-w-full ag-py-2.5 ag-text-base ag-font-semibold"
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          className="ag-w-full ag-py-2.5 ag-bg-red-600 ag-text-base ag-font-semibold ag-gap-2"
          onClick={() => {
            deletePersona(props.persona.id).finally(() => toggleModal());
          }}
        >
          {isPersonaDeleting && <Loader size={18} isButton />} Delete
        </FilledButton>
      </div>
    </Modal>
  );
};
