import { UnsplashRes } from '../model';
import { UNSPLASH_KEY } from 'shared/model';

const unsplashUrl = 'https://api.unsplash.com/search/photos';

const options = () => ({
  headers: { Authorization: `Client-ID ${UNSPLASH_KEY}` }
});

export const stockImagesApi = {
  fetchPics: async (queryStr: string) => {
    const res = await fetch(unsplashUrl + queryStr, options());
    const data: UnsplashRes = await res.json();

    if (!res.ok) {
      const error = new Error();
      error.message = 'Failed to fetch images';
      throw error;
    } else {
      return data.results;
    }
  }
};
