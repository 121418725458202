export const colorList = [
  '#FFFFFF',
  '#E5E5E5',
  '#FADBD8',
  '#FAD7A0',
  '#FCF3CF',
  '#D4EFDF',
  '#D6EAF8',
  '#D7BDE2',
  '#171717'
];

export const gradientList = [
  'linear-gradient(0deg, #FFFFFF, #FFFFFF), radial-gradient(50% 50% at 50% 50%, #FFFFFF 22.32%, #C0C0C0 100%)',
  'radial-gradient(75.92% 75.92% at 50% 50%, #494949 0%, #161616 89.09%)'
];
