import Dropdown from 'components/shared/Dropdown';
import { BsFilter } from 'react-icons/bs';
import Checkbox from 'components/shared/Checkbox';
import { AvatarOptions, GenderOptions } from 'shared/model';
import Divider from 'components/Divider';
import { avatarStore } from 'entities/avatar';

export const FiltersDropdown = () => {
  const {
    genderFilters,
    setGenderFilters,
    resetGenders,
    setTypeFilters,
    typeFilters,
    resetTypeFilters
  } = avatarStore((state) => ({
    genderFilters: state.genderFilters,
    setGenderFilters: state.setGenderFilters,
    resetGenders: state.resetGenders,
    setTypeFilters: state.setTypesFilters,
    typeFilters: state.typeFilters,
    resetTypeFilters: state.resetTypeFilters
  }));

  return (
    <Dropdown
      buttonClassName={
        'ag-w-10 ag-h-10 !ag-border-neutral-300 ag-flex ag-justify-center ag-items-center ag-rounded-md ag-border'
      }
      className={'!ag-border-neutral-300 ag-bg-white'}
      icon={<BsFilter size={20} className="w-5 h-5 ag-text-primary-600" />}
    >
      <div className="ag-pt-5 ag-pb-4 ag-text-neutral-900">
        <div className="ag-pl-3.5 ag-pr-4 ag-flex ag-justify-between ag-w-full ag-items-baseline">
          <button
            className={`ag-text-sm ag-font-inter ag-font-semibold ${
              typeFilters.length || genderFilters.length
                ? 'text-secondary'
                : 'ag-text-gray-300 pointer-events-none'
            }`}
            onClick={() => {
              resetTypeFilters();
              resetGenders();
            }}
          >
            Reset
          </button>
        </div>
        <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-mt-5 ag-pl-3.5 ag-pr-4">
          <Checkbox
            label="Talking Photo"
            onClick={(value) => setTypeFilters(value as AvatarOptions)}
            choosenValues={typeFilters}
            value="talking photo"
          />
          <Checkbox
            label="Custom"
            onClick={(value) => setTypeFilters(value as AvatarOptions)}
            choosenValues={typeFilters}
            value="custom"
          />
        </div>
        <Divider className="ag-w-full ag-my-2 ag-border-neutral-300" />
        <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-pl-3.5 ag-pr-4">
          <Checkbox
            label="Male"
            onClick={(value) => setGenderFilters(value as GenderOptions)}
            choosenValues={genderFilters}
            value="male"
          />
          <Checkbox
            label="Female"
            onClick={(value) => setGenderFilters(value as GenderOptions)}
            choosenValues={genderFilters}
            value="female"
          />
        </div>
      </div>
    </Dropdown>
  );
};
