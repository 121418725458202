import axios from 'axios';
import { PEXELS_KEY } from 'shared/model';

const url = 'https://api.pexels.com/videos/search';

const config = {
  headers: { Authorization: PEXELS_KEY }
};

export const stockVideoApi = {
  fetchVideos: async (queryStr: string = '?query=nature&per_page=10') => {
    const res = await axios.get(url + queryStr, config);

    return res;
  }
};
