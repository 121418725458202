import { FC } from 'react';
import { avatarStore } from 'entities/avatar';
import AvatarItem from 'components/SideBar/Avatars/AvatarsItem';
import { FilledButton, IAvatar } from 'shared';
import { AvatarFilter, filterAvatarStore } from 'features/filter-avatars';
import { FormikErrors } from 'formik';
import { ICreatePersonaReq } from 'entities/persona';

type Props = {
  avatarId: string;
  onChange: (
    value: string
  ) => Promise<void> | Promise<FormikErrors<ICreatePersonaReq>>;
};

export const ChooseAvatar: FC<Props> = ({ avatarId, onChange }) => {
  const {
    filteredCustomTalkingPhotos,
    filteredTalkingPhotos,
    toggleUploadAvatar
  } = avatarStore();

  const { typeFilters } = filterAvatarStore();

  return (
    <div className="ag-w-full ag-mb-14 ag-min-h-96 ag-relative">
      <div className="ag-sticky ag-top-14 ag-bg-neutral-900 ag-z-[49] ag-pt-4 ag-h-[120px]">
        <div className="ag-w-full  ag-flex ag-items-center ag-justify-between ag-mb-2 sm:ag-mb-6 ag-gap-4">
          <div>Choose or upload your own avatar</div>
        </div>
        <div className="ag-mb-6 sm:ag-mb-10 ag-flex ag-gap-2 ag-justify-between">
          <AvatarFilter classAvatarWrapper={'sm:!ag-w-auto'} />
          <FilledButton
            onClick={toggleUploadAvatar}
            className="ag-px-4 ag-py-2 ag-text-sm ag-w-full sm:ag-w-32"
          >
            Upload avatar
          </FilledButton>
        </div>
      </div>
      <div className="ag-h-full ag-overflow-auto ag-scrollbar-none ag-pt-2">
        <div className="ag-grid ag-grid-cols-3 md:ag-grid-cols-5 ag-gap-2 md:ag-gap-4 ag-mb-4">
          {filteredCustomTalkingPhotos &&
            (!typeFilters.length || typeFilters.includes('custom')) &&
            filteredCustomTalkingPhotos.map((avatar: IAvatar) => (
              <AvatarItem
                key={avatar.id}
                onChooseAvatar={() => onChange(avatar.id)}
                isActive={avatarId === avatar.id}
                avatar={avatar}
                // openModal={() => openInfoModal(avatar)}
              />
            ))}
          {filteredTalkingPhotos &&
            (!typeFilters.length || typeFilters.includes('talking photo')) &&
            filteredTalkingPhotos.map((avatar: IAvatar) => (
              <AvatarItem
                key={avatar.id}
                onChooseAvatar={() => onChange(avatar.id)}
                isActive={avatarId === avatar.id}
                avatar={avatar}
                // openModal={() => openInfoModal(avatar)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
