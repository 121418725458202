import { ComponentType, FC, useState } from 'react';
import { FaRegUser } from 'react-icons/fa';
import {
	IPersona,
	IPersonaFormik,
} from 'entities/persona';
import { FormikProps, useFormik } from 'formik';
import { cn, scrollBarClasses } from 'shared/lib';
import { EditPersonaForm } from 'features/edit-persona-form';
import { FaRegFaceSmile } from 'react-icons/fa6';

type Tool = {
	name: string;
	icon: FC<{ className: string }>;
};

const tools: Tool[] = [
	{
		name: 'Identity',
		icon: FaRegUser,
	},
	{
		name: 'Persona',
		icon: FaRegFaceSmile,
	},
];

type Props = {
	// eslint-disable-next-line
	formik: ReturnType<typeof useFormik<IPersonaFormik>>;
	persona: IPersona;
	IdentityFormComponent: ComponentType<{ formik: FormikProps<IPersonaFormik>; persona: IPersona }>;
};

export const EditPersonaSidebar = ({
																		 formik,
																		 persona,
																		 IdentityFormComponent,
																	 }: Props) => {
	const [tool, setTool] = useState(tools[0].name);
	const [knowledgeFiles, setKnowledgeFiles] = useState<string[]>((persona.knowledgeSources ?? []).map((kn) => kn.asset.name)
);
	return (
		<div
			className='ag-w-full max-lg:ag-overflow-auto lg:ag-w-[556px] ag-bg-white ag-border-r ag-border-neutral-200 lg:ag-grid max-lg:ag-grid-rows-[4.625rem,auto] lg:ag-grid-cols-[5rem,auto]'>
			<div
				className='lg:ag-h-full max-lg:ag-border-b lg:ag-border-r ag-border-b ag-border-neutral-200 lg:ag-pt-6 ag-flex lg:ag-flex-col'>
				{tools.map((t) => (
					<label
						key={t.name}
						className={`ag-w-full ag-cursor-pointer ag-h-[74px] ag-flex ag-gap-1 ag-flex-col ag-items-center ag-justify-center ag-text-neutral-600 ${
							tool === t.name ? 'ag-bg-neutral-100' : ''
						}`}
					>
						<input
							type='radio'
							name='tool'
							className='ag-sr-only'
							value={t.name}
							onChange={() => setTool(t.name)}
						/>
						<t.icon className='ag-w-5 ag-h-5' />
						<span className='ag-text-xs ag-font-semibold'>{t.name}</span>
					</label>
				))}
			</div>
			<div className={cn('ag-px-4 ag-py-8 ag-overflow-y-auto', scrollBarClasses)}>
				{tool === tools[0].name && (
					<IdentityFormComponent persona={persona} formik={formik} />
				)}
				{tool === tools[1].name && (
					<EditPersonaForm formik={formik} files={knowledgeFiles} setFiles={setKnowledgeFiles} />
				)}
			</div>
		</div>
	);
};
