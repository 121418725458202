import { personaStore, Visibility } from 'entities/persona';
import { Persona } from 'widgets/personas-list/ui/Persona';
import clsx from 'clsx';
import { useAuthStore } from 'stores/useAuthStore';
import { cn } from 'shared/lib';

export const PersonasList = () => {
  const { personas } = personaStore((state) => ({
    personas: state.personas
  }));
  const isAuth = useAuthStore((state) => state.isAuth);
  const userPersonas = personas?.filter(
    (item) => item.visibility === Visibility.PRIVATE
  );

  const stockPersonas = personas?.filter(
    (item) => item.visibility !== Visibility.PRIVATE
  );

  return (
    <>
      {isAuth && (
        <>
          <h2
            className={clsx(
              'ag-font-semibold dark:ag-text-white',
              userPersonas?.length ? 'ag-mb-4' : 'ag-mb-2'
            )}
          >
            My Video Agents
          </h2>
          <div
            className={clsx(
              'ag-w-full ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6',
              userPersonas?.length ? 'ag-grid' : ''
            )}
          >
            {userPersonas?.length ? (
              userPersonas.map((persona) => (
                <Persona key={persona.id} persona={persona} />
              ))
            ) : (
              <div className={'ag-text-neutral-600 ag-w-full'}>
                You don’t have any Video Agent yet.
              </div>
            )}
          </div>
        </>
      )}

      <h2
        className={cn(
          'ag-font-semibold dark:ag-text-white',
          stockPersonas.length ? 'ag-mb-4' : 'ag-mb-2'
        )}
      >
        Stock Video Agents
      </h2>
      <div
        className={cn(
          'ag-w-full ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6',
          stockPersonas?.length ? 'ag-grid' : ''
        )}
      >
        {stockPersonas.length ? (
          stockPersonas.map((persona) => (
            <Persona key={persona.id} persona={persona} />
          ))
        ) : (
          <div className={'ag-text-neutral-600 ag-w-full'}>
            There are no stock video agents yet.
          </div>
        )}
      </div>
    </>
  );
};
