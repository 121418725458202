import { DailyEvent } from '@daily-co/daily-js';

const STATE_IDLE = 'STATE_IDLE';
const STATE_CREATING = 'STATE_CREATING';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';
const STATE_HAIRCHECK = 'STATE_HAIRCHECK';

export const DAILY_CALL_STATES = {
  STATE_IDLE,
  STATE_CREATING,
  STATE_JOINING,
  STATE_JOINED,
  STATE_LEAVING,
  STATE_ERROR,
  STATE_HAIRCHECK
};

export const events: DailyEvent[] = [
  'joined-meeting',
  'left-meeting',
  'error',
  'camera-error'
];

export const transcriptionEvents: DailyEvent[] = [
  'transcription-started',
  'transcription-stopped',
  'transcription-error',
  'transcription-message'
];
