import {
  ComponentType,
  FC,
  FunctionComponent,
  SVGProps,
  useState
} from 'react';
import { FaRegUser } from 'react-icons/fa';
import { FormikProps } from 'formik';

import { cn, scrollBarClasses } from 'shared/lib';
import { FaRegFaceSmile } from 'react-icons/fa6';
import { ITavusPersona, ITavusPersonaFormik } from 'entities/tavus/persona';
import { AvatarEditPersonaForm } from 'features/avatar-edit-persona-form';
import StockImagesProvider from 'features/list-stock-images/model/useStockImages';
import { ReactComponent as BackgroundIcon } from 'shared/ui/img/background.svg';

type Tool = {
  name: string;
  icon: FC<{ className: string }> | FunctionComponent<SVGProps<SVGSVGElement>>;
};

const tools: Tool[] = [
  {
    name: 'Identity',
    icon: FaRegUser
  },
  {
    name: 'Persona',
    icon: FaRegFaceSmile
  },
  {
    name: 'Background',
    icon: BackgroundIcon
  }
];

type Props = {
  formik: FormikProps<ITavusPersonaFormik>;
  persona: ITavusPersona;
  IdentityFormComponent: ComponentType<{
    formik: FormikProps<ITavusPersonaFormik>;
  }>;
  ChooseBackgroundComponent: ComponentType<{
    selectedBackground: string;
    onChange: (background: string) => void;
    customImageBackgrounds: string[];
    customVideoBackgrounds: string[];
    setCustomImageBackgrounds: (backgrounds: string[]) => void;
    setCustomVideoBackgrounds: (backgrounds: string[]) => void;
    theme?: 'light' | 'dark';
  }>;
};

export const AvatarEditPersonaSidebar = ({
  formik,
  persona,
  IdentityFormComponent,
  ChooseBackgroundComponent
}: Props) => {
  const [tool, setTool] = useState(tools[0].name);

  const [customImageBackgrounds, setCustomImageBackgrounds] = useState<
    string[]
  >([]);

  const [customVideoBackgrounds, setCustomVideoBackgrounds] = useState<
    string[]
  >([]);
  return (
    <div className="ag-w-full max-lg:ag-overflow-auto lg:ag-w-[556px] ag-bg-white ag-border-r ag-border-neutral-200 lg:ag-grid max-lg:ag-grid-rows-[4.625rem,auto] lg:ag-grid-cols-[5rem,auto]">
      <div className="lg:ag-h-full max-lg:ag-border-b lg:ag-border-r ag-border-b ag-border-neutral-200 lg:ag-pt-6 ag-flex lg:ag-flex-col">
        {tools.map((t) => (
          <label
            key={t.name}
            className={`ag-w-full ag-cursor-pointer ag-h-[74px] ag-flex ag-gap-1 ag-flex-col ag-items-center ag-justify-center ag-text-neutral-600 ${
              tool === t.name ? 'ag-bg-neutral-100' : ''
            }`}
          >
            <input
              type="radio"
              name="tool"
              className="ag-sr-only"
              value={t.name}
              onChange={() => setTool(t.name)}
            />
            <t.icon className="ag-w-5 ag-h-5" />
            <span className="ag-text-xs ag-font-semibold">{t.name}</span>
          </label>
        ))}
      </div>
      <div
        className={cn('ag-px-4 ag-py-8 ag-overflow-y-auto', scrollBarClasses)}
      >
        {tool === tools[0].name && <IdentityFormComponent formik={formik} />}
        {tool === tools[1].name && (
          <AvatarEditPersonaForm persona={persona} formik={formik} />
        )}
        {tool === tools[2].name && (
          <StockImagesProvider>
            <ChooseBackgroundComponent
              selectedBackground={formik.values.customBackground}
              onChange={(color: string) => {
                formik.setFieldValue('customBackground', color);
              }}
              theme={'light'}
              customImageBackgrounds={customImageBackgrounds}
              customVideoBackgrounds={customVideoBackgrounds}
              setCustomImageBackgrounds={setCustomImageBackgrounds}
              setCustomVideoBackgrounds={setCustomVideoBackgrounds}
            />
          </StockImagesProvider>
        )}
      </div>
    </div>
  );
};
