import { IoIosClose } from 'react-icons/io';
import { avatarStore } from 'entities/avatar';

export const SelectedFilters = () => {
  const { genderFilters, setGenderFilters, typesFilters, setTypeFilters } =
    avatarStore((state) => ({
      genderFilters: state.genderFilters,
      setGenderFilters: state.setGenderFilters,
      setTypeFilters: state.setTypesFilters,
      typesFilters: state.typeFilters
    }));

  if (!genderFilters.length && !typesFilters.length) return null;

  return (
    <div className="ag-flex ag-flex-wrap ag-gap-3 ag-px-4 ag-mb-4 ag-text-primary-700 ag-text-xs">
      {genderFilters.map((filter) => (
        <div
          key={filter}
          className="ag-flex ag-items-center ag-bg-primary-50 ag-px-2 ag-py-1 ag-rounded-lg"
        >
          <span>{filter}</span>
          <button onClick={() => setGenderFilters(filter)} className="ag-ml-1">
            <IoIosClose size={16} />
          </button>
        </div>
      ))}
      {typesFilters.map((filter) => (
        <div
          key={filter}
          className="ag-flex ag-items-center ag-bg-primary-50 ag-px-2 ag-py-1 ag-rounded-lg"
        >
          <span>{filter}</span>
          <button onClick={() => setTypeFilters(filter)} className="ag-ml-1">
            <IoIosClose size={16} />
          </button>
        </div>
      ))}
    </div>
  );
};
