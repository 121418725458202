import { useMemo } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { IVoice } from 'shared/model';
import voiceStore from 'stores/useVoiceStore';
import { capitalize } from 'shared/lib';

type Props = {
  currentVoice: IVoice | null;
  onClick: () => void;
};

export const VoiceSelectButton = ({ currentVoice, onClick }: Props) => {
  const { languages } = voiceStore((state) => ({
    selectVoiceModal: state.selectVoiceModal,
    languages: state.languages
  }));

  const getCountryIcon = useMemo(() => {
    return languages?.find((l) => l.language === currentVoice?.language)
      ?.country?.image;
  }, [currentVoice]);

  const displayVoiceString = (voice: IVoice) => {
    return `${capitalize(voice.name)} - ${capitalize(voice.language)}`;
  };
  return (
    <button
      type="button"
      className="group relative w-[231px] whitespace-nowrap flex items-center gap-2 border border-gray-300 rounded-md p-2 text-sm"
      onClick={() => onClick()}
    >
      {currentVoice ? (
        <div className="text-neutral-600 overflow-hidden text-ellipsis flex items-center">
          <span className="flex items-center whitespace-nowrap">
            <span className="mr-2 inline-block size-5 p-0">
              <img src={getCountryIcon} className="size-full object-contain" />
            </span>
            {displayVoiceString(currentVoice)}
          </span>
        </div>
      ) : (
        'Select'
      )}
      {currentVoice && (
        <span
          className={`text-white hidden absolute py-2 px-3 -bottom-10 -left-0 whitespace-nowrap rounded-lg bg-[#000] text-xs z-[99999]
      group-hover:block`}
        >
          {displayVoiceString(currentVoice)}
        </span>
      )}

      <AiFillCaretDown className={'transform rotate-0'} />
    </button>
  );
};
