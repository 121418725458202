import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import {
  ListPersonas,
  ITavusPersona,
  ICreateTavusPersonaReq,
  ICreateTavusPersonaRes
} from '../model';

const tavusPersonasEndpoint = '/persona';

export const tavusPersonaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<ITavusPersona> = await tavusHttpService.get(
      `${tavusPersonasEndpoint}/${id}`
    );

    return data;
  },
  delete: (id: string) => {
    return tavusHttpService.delete(`${tavusPersonasEndpoint}/${id}`);
  },
  fetchAll: async () => {
    const { data }: AxiosResponse<ListPersonas> = await tavusHttpService.get(
      tavusPersonasEndpoint + '/'
    );

    return data.items;
  },
  create: async (personaData: ICreateTavusPersonaReq) => {
    const { data }: AxiosResponse<ICreateTavusPersonaRes> =
      await tavusHttpService.post(tavusPersonasEndpoint + '/', {
        ...personaData
      });

    return data;
  },
  update(personaId: string, personaData: ICreateTavusPersonaReq) {
    return tavusHttpService.patch(
      `${tavusPersonasEndpoint}/${personaId}`,
      personaData
    );
  }
};
