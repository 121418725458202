import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react';
import { Fragment, useState } from 'react';

import { HiOutlineDotsVertical, HiOutlineCheck } from 'react-icons/hi';
import { BiX, BiCaptions } from 'react-icons/bi';

import { cn } from 'shared';

import Divider from 'components/Divider';

import { dailyCallStore } from 'features/daily-call/model';

const TavusEmbedCallSettings = () => {
  const { withCaptions, toggleCaptions } = dailyCallStore();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettings = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  return (
    <>
      <button
        onClick={toggleSettings}
        className="ag-rounded-full ag-size-10 ag-flex ag-items-center ag-justify-center disabled:ag-opacity-80 ag-bg-neutral-200 ag-border ag-border-neutral-300 ag-relative"
      >
        <HiOutlineDotsVertical className="ag-text-xl ag-text-neutral-700" />
      </button>

      <Transition show={isSettingsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="ag-relative ag-z-50"
          onClose={toggleSettings}
        >
          <TransitionChild
            as={Fragment}
            enter="ag-ease-out ag-duration-300"
            enterFrom="ag-opacity-0"
            enterTo="ag-opacity-100"
            leave="ag-ease-in ag-duration-200"
            leaveFrom="ag-opacity-100"
            leaveTo="ag-opacity-0"
          >
            <div className="ag-fixed ag-inset-0 ag-bg-neutral-600 ag-bg-opacity-50 ag-transition-opacity" />
          </TransitionChild>
          <div
            className={cn(
              `ag-fixed ag-z-10 sm:ag-flex ag-scrollbar-none ag-w-full ag-bottom-0 ag-left-0 sm:ag-inset-0`
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ag-ease-out ag-duration-300"
              enterFrom="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
              enterTo="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leave="ag-ease-in ag-duration-200"
              leaveFrom="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leaveTo="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
            >
              <DialogPanel
                className={cn(
                  'ag-relative ag-transform ag-rounded-lg ag-bg-white ag-text-left ag-shadow-lg ag-transition-all sm:ag-m-auto ag-scrollbar-none ag-border ag-border-neutral-200 sm:ag-w-[343px]'
                )}
              >
                <BiX
                  className="ag-absolute ag-top-3 ag-right-3 ag-text-3xl ag-text-neutral-gray ag-cursor-pointer ag-z-[99999]"
                  onClick={toggleSettings}
                />
                <div className="ag-p-4 ag-font-semibold">Settings</div>
                <Divider />
                <div className="ag-px-4 ag-py-2 ag-text-sm ag-text-neutral-700">
                  <label
                    htmlFor="captions"
                    className="ag-flex ag-items-center ag-justify-between ag-py-2 ag-cursor-pointer"
                  >
                    <div className="ag-flex ag-items-center ag-gap-2">
                      <BiCaptions />
                      <div>Captions</div>
                    </div>
                    <input
                      id="captions"
                      type="checkbox"
                      className="!ag-hidden"
                      checked={withCaptions}
                      onChange={toggleCaptions}
                    />
                    <div
                      className={cn(
                        'ag-border ag-border-indigo-600 ag-text-indigo-600 ag-rounded ag-size-4 ag-flex ag-items-center ag-justify-center ag-cursor-pointer',
                        withCaptions && 'ag-bg-indigo-50'
                      )}
                    >
                      {withCaptions ? <HiOutlineCheck /> : null}
                    </div>
                  </label>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TavusEmbedCallSettings;
