import React, { FC, useEffect, useRef } from 'react';

interface IProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  classNames?: string;
  background?: string;
}

export const GreenScreenVideo: FC<IProps> = ({
  videoRef,
  classNames,
  background
}) => {
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const greenThreshold = useRef(130);
  const animationFrame = useRef<number | null>(null);

  const blackThreshold = 30;
  const softEdgeFactor = 0.9;
  const tolerance = 50;
  const cropOffset = 0;
  const computeFrame = () => {
    if (!canvasRef.current || !contextRef.current || !videoRef?.current) return;

    const video = videoRef.current as HTMLVideoElement;
    const width = video.videoWidth - cropOffset * 2;
    const height = video.videoHeight - cropOffset * 2;
    canvasRef.current.width = width;
    canvasRef.current.height = height;

    const videoRatio = video.videoWidth / video.videoHeight;
    const containerRatio = window.innerWidth / window.innerHeight;
    if (containerRatio < videoRatio) {
      canvasRef.current.style.objectFit = 'cover';
    } else {
      canvasRef.current.style.objectFit = 'contain';
    }
    contextRef.current.drawImage(
      video,
      cropOffset,
      cropOffset,
      width,
      height,
      0,
      0,
      width,
      height
    );

    if (!background) return;
    const frame = contextRef.current.getImageData(0, 0, width, height);
    const l = frame.data.length / 4;

    for (let i = 0; i < l; i++) {
      const offset = i * 4;
      const r = frame.data[offset];
      const g = frame.data[offset + 1];
      const b = frame.data[offset + 2];

      if (
        g > greenThreshold.current &&
        g > r + tolerance &&
        g > b + tolerance
      ) {
        frame.data[offset + 3] = 0;
      } else if (g > greenThreshold.current * 0.5 && g > r && g > b) {
        const greenAmount =
          (g - greenThreshold.current * 0.5) /
          (greenThreshold.current * (1 - softEdgeFactor));
        frame.data[offset + 3] = 255 * (1 - greenAmount);
      }

      if (r < blackThreshold && g < blackThreshold && b < blackThreshold) {
        frame.data[offset + 3] = 255;
      }
    }

    contextRef.current.putImageData(frame, 0, 0);
  };

  const timerCallback = () => {
    const video = videoRef.current as HTMLVideoElement;
    if (video && !video.paused && !video.ended) {
      computeFrame();
      animationFrame.current = requestAnimationFrame(timerCallback);
    }
  };

  const handlePlay = () => {
    if (!videoRef?.current || !canvasRef.current) return;

    const video = videoRef.current as HTMLVideoElement;
    const canvas = canvasRef.current;
    contextRef.current = canvas.getContext('2d', { willReadFrequently: true });

    canvas.width = video.videoWidth - cropOffset * 2;
    canvas.height = video.videoHeight - cropOffset * 2;
    timerCallback();
  };

  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (video) {
      if (!video.paused) {
        handlePlay();
      } else {
        video.addEventListener('play', handlePlay);
      }
    }
    return () => {
      if (video) {
        video.removeEventListener('play', handlePlay);
      }
    };
  }, [videoRef]);

  useEffect(() => {
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
      timerCallback();
    }
  }, [background]);
  return (
    <canvas
      style={{
        background: !background?.includes('video')
          ? background?.replace('image;', '')
          : undefined,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
      ref={canvasRef}
      width={'100%'}
      height={'100%'}
      className={classNames}
    />
  );
};
